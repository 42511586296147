import React, { useState } from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';
import styled from 'styled-components';
import { H3, Body } from 'TMComponents/Typography';
import { breakpoints } from 'TMConstants';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { Button } from 'TMComponents/Button';
import NavLink from 'TMComponents/NavLink';
import ChevronRight from 'TMComponents/Icons/ChevronRight';

const Wrapper = styled.div`
    border-bottom: 2px solid ${(props) => props.theme.colors.greys.light};
    max-width: 568px;
    padding: 16px 0 24px 0;
    text-align: left;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        border-bottom: none;
        padding: 24px 0 36px 0;
    }
`;

const Header = styled.button`
    background-color: transparent;
    border: none;
    color: ${({ categoryAlternativeColor, theme }) =>
        categoryAlternativeColor
            ? theme.colorSystem.primary[4]
            : theme.colorSystem.primary[1]};
    margin-bottom: 24px;
    padding: 0;
    text-align: left;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const QuestionText = styled(Body)`
    margin: 0;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const Title = styled(H3)`
    margin-bottom: 0;
`;
const ChevronWrapper = styled.div`
    margin: auto 0;
    padding-left: 16px;
`;
const Chevron = styled(ChevronRight)`
    transform: rotate(90deg);
    transition: transform 0.3s;
    width: 18px;

    ${(props) => props.open && 'transform: rotate(270deg);'}

    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: none;
    }
`;

const Inner = styled.div`
    ${(props) => !props.open && 'display: none'};
    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: flex;
        flex-direction: column;
    }
`;

const Questions = styled(FlexWrapper)`
    flex-direction: column;
    margin-bottom: 24px;
`;
const Question = styled(NavLink)`
    border-bottom: 2px solid ${(props) => props.theme.colors.greys.light};
    display: block;
    padding: 8px 0;
`;

const SeeMoreButton = styled(Button)`
    margin-bottom: 16px;
    margin-top: 24px;
`;

const CategorySummary = ({
    name,
    categoryAlternativeColor,
    nameLink,
    posts,
    alwaysOpen
}) => {
    const [open, setOpen] = useState(alwaysOpen);
    const titleComp = <Title grey>{name}</Title>;
    return (
        <Wrapper data-testid="category-card-wrapper">
            <Header
                type="button"
                onClick={() => !alwaysOpen && setOpen(!open)}
                aria-label="Toggle category"
                categoryAlternativeColor={categoryAlternativeColor}>
                <FlexWrapper justify="space-between" align="center">
                    <FlexWrapper align="center">
                        {nameLink ? (
                            <NavLink href={nameLink}>{titleComp}</NavLink>
                        ) : (
                            titleComp
                        )}
                    </FlexWrapper>
                    {!alwaysOpen && <Chevron open={open} />}
                </FlexWrapper>
            </Header>
            <Inner open={open} data-testid="inner">
                <Questions>
                    {posts.map((question) => (
                        <Question key={question.id} href={question.url}>
                            <FlexWrapper
                                justify="space-between"
                                align="center">
                                <QuestionText grey>
                                    {question.name}
                                </QuestionText>
                                <ChevronWrapper>
                                    <ChevronRight />
                                </ChevronWrapper>
                            </FlexWrapper>
                        </Question>
                    ))}
                </Questions>
                {nameLink && (
                    <FlexWrapper>
                        <SeeMoreButton link={nameLink}>
                            See more like this
                        </SeeMoreButton>
                    </FlexWrapper>
                )}
            </Inner>
        </Wrapper>
    );
};

CategorySummary.propTypes = {
    name: string.isRequired,
    nameLink: string,
    categoryAlternativeColor: bool,
    alwaysOpen: bool,
    posts: arrayOf(
        shape({
            name: string,
            url: string
        })
    ).isRequired
};

CategorySummary.defaultProps = {
    nameLink: undefined,
    alwaysOpen: false,
    categoryAlternativeColor: false
};

export default CategorySummary;
