import { string, arrayOf, shape, bool, oneOf } from 'prop-types';
import { Button } from 'TMComponents/Button';
import imageProps from './imageProps';

export const submenuLinkProps = {
    text: string,
    link: string
};

export default {
    mobileLogo: shape(imageProps),
    desktopLogo: shape(imageProps),
    noTransparentNav: bool,
    isTop: bool,
    mobileButtonIcon: shape(imageProps),
    hideNav: bool,
    desktopRightSection: oneOf([Button.propTypes]),
    mobileBottomSection: arrayOf(oneOf([Button.propTypes, imageProps])),
    menuItems: arrayOf(
        shape({
            id: string,
            title: string,
            submenu: arrayOf(shape(submenuLinkProps))
        })
    )
};
