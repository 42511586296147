export const breakpoints = {
    smallMobile: 370,
    mobile: 412,
    smallTablet: 648,
    mobileTablet: 768,
    tabletDesktop: 1025,
    laptop: 1440,
    smallDesktop: 1600
};
export const headerHeight = {
    mobile: 100,
    desktop: 94
};
