/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-children-prop */

import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Container from 'TMComponents/Container';
import ReactMarkdown from 'react-markdown';
import NavLink from 'TMComponents/NavLink';
import {
    H2,
    H3,
    H4,
    H5,
    Body,
    BodyLarge,
    BodySmall,
    BodyExtraSmall,
    Bold
} from 'TMComponents/Typography';
import List from 'TMComponents/List';
import ListItem from 'TMComponents/List/ListItem';

const Link = styled(NavLink)`
    ${Bold}
    text-decoration: underline;
    color: ${({ theme }) => theme.colorSystem.primary[4]};
    &:hover {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
    &:focus {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
`;
const Wrapper = styled(Container)`
    max-width: 1280px;
    ${(props) =>
        props.maxWidth &&
        `
        max-width: ${props.maxWidth}px; 
    `};
    ${(props) =>
        props.centerAlignText &&
        `
        text-align:center;
    `};
    ${({ whiteText, theme }) =>
        whiteText && `color: ${theme.colorSystem.UIText[3]} !important;`}
`;

const ContentParser = ({
    copy,
    width,
    className = '',
    listCheckMarks,
    bodyCopyVariant = 'default',
    leftAlign,
    centerAlignText,
    backgroundVariant
}) => {
    const theme = useTheme();

    return (
        <Wrapper
            leftAlign={leftAlign}
            maxWidth={width}
            centerAlignText={centerAlignText}
            whiteText={backgroundVariant === theme.colorSystem.primary[1]}>
            <ReactMarkdown
                children={copy}
                components={{
                    p: ({ children }) => {
                        switch (bodyCopyVariant) {
                            case 'large':
                                return <BodyLarge>{children}</BodyLarge>;
                            case 'small':
                                return <BodySmall>{children}</BodySmall>;
                            case 'extra_small':
                                return (
                                    <BodyExtraSmall>
                                        {children}
                                    </BodyExtraSmall>
                                );
                            default:
                                return <Body>{children}</Body>;
                        }
                    },
                    a: ({ href, children }) => (
                        <Link href={href}>{children}</Link>
                    ),
                    ul: ({ children }) => (
                        <List
                            listCheckMarks={listCheckMarks}
                            centerAlignText={centerAlignText}>
                            {children}
                        </List>
                    ),
                    li: ({ key, children }) => (
                        <ListItem
                            key={key}
                            listCheckMarks={listCheckMarks}>
                            {children}
                        </ListItem>
                    ),
                    h1: ({ children }) => <H2>{children}</H2>,
                    h2: ({ children }) => <H2>{children}</H2>,
                    h3: ({ children }) => <H3>{children}</H3>,
                    h4: ({ children }) => <H4>{children}</H4>,
                    h5: ({ children }) => <H5>{children}</H5>,
                    h6: ({ children }) => <H5>{children}</H5>
                }}
                className={className}
            />
        </Wrapper>
    );
};

ContentParser.propTypes = {
    copy: string.isRequired,
    className: string,
    listCheckMarks: bool,
    bodyCopyVariant: oneOf(['default', 'large', 'small', 'extra_small']),
    centerAlignText: bool
};

export default ContentParser;
