import React from 'react';
import FlexWrapper from 'TMComponents/FlexWrapper';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';

const List = styled(FlexWrapper)`
    max-width: 1280px;
`;

const ImageListImage = styled.img`
    margin: 20px;
    max-width: 100%;
`;

const ImageList = ({ images }) => (
    <List justify="center" align="center" wrap>
        {images.map(({ url, alt, ...rest }) => (
            <ImageListImage key={url} src={url} alt={alt} {...rest} />
        ))}
    </List>
);

ImageList.propTypes = {
    images: arrayOf(
        shape({
            url: string,
            alt: string
        })
    ).isRequired
};

export default ImageList;
