/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { StructuredText, renderRule } from 'react-datocms';
import {
    isHeading,
    isParagraph,
    isList,
    isLink,
    isListItem
} from 'datocms-structured-text-utils';
import { object, bool, oneOf, string } from 'prop-types';
import styled, { useTheme } from 'styled-components';
import List from 'TMComponents/List';
import ListItem from 'TMComponents/List/ListItem';
import NavLink from 'TMComponents/NavLink';
import Image from 'TMComponents/Image';
import { Button } from 'TMComponents/Button';
import SharedOptions from 'TMComponents/ComponentMapper/SharedOptions';
import Container from 'TMComponents/Container';
import {
    H1,
    H1Alt,
    H2,
    H3,
    H4,
    H5,
    Body,
    BodyLarge,
    BodySmall,
    BodyExtraSmall,
    Bold
} from 'TMComponents/Typography';

const Wrapper = styled(Container)`
    position: relative;
    max-width: 568px;

    ${({ whiteText, theme }) =>
        whiteText && `color: ${theme.colorSystem.UIText[3]} !important;`}
    ${({ smallMargins }) =>
        smallMargins &&
        `
        h1,h2,h3,h4,h5,h6,p {
            margin-bottom: 0;
        }
    `}
    ${(props) =>
        props.centerAlignText &&
        `
        text-align:center;
        `}
`;

const Link = styled(NavLink)`
    ${Bold}
    text-decoration: underline;
    color: ${({ theme }) => theme.colorSystem.primary[4]};
    &:hover {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
    &:focus {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
`;

const ContentParserStructured = ({
    structuredCopy,
    listCheckMarks,
    bodyCopyVariant = 'default',
    smallMargins,
    allowH1,
    alternativeH1,
    leftAlign,
    centerAlignText,
    backgroundVariant
}) => {
    const theme = useTheme();

    return (
        <Wrapper
            leftAlign={leftAlign}
            centerAlignText={centerAlignText}
            smallMargins={smallMargins}
            whiteText={backgroundVariant === theme.colorSystem.primary[1]}>
            <StructuredText
                data={structuredCopy}
                renderBlock={({ record }) => {
                    const {
                        __typename,
                        hideOnMobile,
                        hideOnDesktop,
                        whiteBackground,
                        ...item
                    } = record;
                    const ComponentDictionary = {
                        DatoCmsImage: Image,
                        DatoCmsButton: Button
                    };
                    const Component = ComponentDictionary[__typename];
                    if (!Component) return null;
                    return (
                        <SharedOptions
                            whiteBackground={whiteBackground}
                            hideOnMobile={hideOnMobile}
                            hideOnDesktop={hideOnDesktop}
                            noSideMargins={
                                whiteBackground || item.imageCover
                            }>
                            <Component
                                {...item}
                                leftAlign={!centerAlignText}
                            />
                        </SharedOptions>
                    );
                }}
                customNodeRules={[
                    renderRule(isHeading, ({ node, children, key }) => {
                        switch (node.level) {
                            case 1:
                                if (!allowH1)
                                    return <H2 key={key}>{children}</H2>;
                                if (alternativeH1)
                                    return (
                                        <H1Alt key={key}>{children}</H1Alt>
                                    );

                                return <H1 key={key}>{children}</H1>;

                            case 3:
                                return <H3 key={key}>{children}</H3>;
                            case 4:
                                return <H4 key={key}>{children}</H4>;
                            case 5:
                                return <H5 key={key}>{children}</H5>;
                            case 6:
                                return <H5 key={key}>{children}</H5>;
                            default:
                                return <H2 key={key}>{children}</H2>;
                        }
                    }),

                    renderRule(isParagraph, ({ children, key }) => {
                        switch (bodyCopyVariant) {
                            case 'large':
                                return (
                                    <BodyLarge key={key}>
                                        {children}
                                    </BodyLarge>
                                );
                            case 'small':
                                return (
                                    <BodySmall key={key}>
                                        {children}
                                    </BodySmall>
                                );
                            case 'extra_small':
                                return (
                                    <BodyExtraSmall key={key}>
                                        {children}
                                    </BodyExtraSmall>
                                );
                            default:
                                return <Body key={key}>{children}</Body>;
                        }
                    }),

                    renderRule(isList, ({ node, children, key }) => (
                        <List
                            key={key}
                            markerStyle={node.style}
                            listCheckMarks={listCheckMarks}
                            centerAlignText={centerAlignText}>
                            {children}
                        </List>
                    )),

                    renderRule(isListItem, ({ key, children }) => (
                        <ListItem
                            key={key}
                            listCheckMarks={listCheckMarks}>
                            {children}
                        </ListItem>
                    )),
                    renderRule(isLink, ({ node, children, key }) => (
                        <Link key={key} href={node.url}>
                            {children}
                        </Link>
                    ))
                ]}
            />
        </Wrapper>
    );
};

ContentParserStructured.propTypes = {
    structuredCopy: object.isRequired,
    listCheckMarks: bool,
    leftAlign: bool,
    smallMargins: bool,
    bodyCopyVariant: oneOf(['default', 'large', 'small', 'extra_small']),
    allowH1: bool,
    alternativeH1: bool,
    centerAlignText: bool,
    backgroundVariant: string
};

export default ContentParserStructured;
