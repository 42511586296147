/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, string, bool, shape, oneOfType } from 'prop-types';
import styled from 'styled-components';

import Container from 'TMComponents/Container';

const Wrapper = styled(Container)`
    overflow: auto;
    padding: 16px 0;
`;

const TableWrapper = styled.table`
    margin: 0 auto;
    margin-bottom: 32px;
    text-align: left;
`;

const HeaderCell = styled.th`
    background-color: ${(props) => props.theme.colorSystem.primary[4]};
    color: ${({ theme }) => theme.colorSystem.primary[3]};
    padding: 32px 16px;

    width: ${(props) => 60 / (props.columns - 1)}%;

    &:first-child {
        width: ${(props) =>
            props.uniformWidth ? 60 / (props.columns - 1) : '40'}%;
    }
`;

const TableRow = styled.tr`
    &:nth-child(even) td {
        background-color: ${(props) => props.theme.colors.secondary};
    }

    &:nth-child(odd) td {
        background-color: ${(props) => props.theme.colors.greys.lightest};
    }
`;

const Cell = styled.td`
    padding: 24px 16px;
`;

const Table = ({ data, hasUniformWidth, className }) => {
    const { headings, body } =
        typeof data === 'string' ? JSON.parse(data) : data;
    return (
        <Wrapper size="large">
            <TableWrapper className={className}>
                <thead>
                    <tr>
                        {headings.map((text) => (
                            <HeaderCell
                                key={text}
                                columns={headings.length}
                                uniformWidth={hasUniformWidth}>
                                {text}
                            </HeaderCell>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {body.map((cells, i) => (
                        <TableRow key={i}>
                            {cells.map((text, j) => (
                                <Cell key={`${text}-${j}`}>{text}</Cell>
                            ))}
                        </TableRow>
                    ))}
                </tbody>
            </TableWrapper>
        </Wrapper>
    );
};

Table.propTypes = {
    className: string,
    data: oneOfType([
        shape({
            headings: arrayOf(string).isRequired,
            body: arrayOf(arrayOf(string)).isRequired
        }),
        string
    ]).isRequired,
    hasUniformWidth: bool
};

Table.defaultProps = {
    className: '',
    hasUniformWidth: false
};

export default Table;
