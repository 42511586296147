import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { H4 } from 'TMComponents/Typography';
import ContentParser from 'TMComponents/ContentParser';
import ChevronRight from '../../Icons/ChevronRight';
import { themeObj } from '../../GlobalStyle';

const Wrapper = styled.div`
    background-color: ${themeObj.colorSystem.primary['3']};
    border-radius: 16px;
    box-shadow: 0px 4px 23px 0px rgba(0, 48, 87, 0.1);
    padding: 16px;
    margin-bottom: 12px;
`;

const AccordionTitle = styled(H4)`
    margin: 0;
    max-width: 475px;
`;

const AccordionContent = styled(ContentParser)`
    max-height: ${(props) => (props.show ? '100%' : '0px')};
    overflow: hidden;

    p {
        padding-top: 8px;
        box-sizing: border-box;
        margin: 0;
    }
`;

const TitleWrapper = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0px !important;
    background: none;
    border: 0;
    cursor: pointer;
    text-align: left;
    color: unset;
`;

const Icon = styled(ChevronRight)`
    width: 25px;
    height: 18px;
    text-align: right;
    transform: rotate(90deg);
    transition: all 0.3s;

    ${(props) =>
        props.spin &&
        `
      transform: rotate(270deg);
    `}
`;

const Item = ({ open, title, summary }) => {
    const [active, setActive] = useState(open);

    const toggle = () => {
        setActive((prevActive) => !prevActive);
    };

    return (
        <Wrapper>
            <TitleWrapper onClick={toggle}>
                <AccordionTitle>{title}</AccordionTitle>
                <Icon spin={active} />
            </TitleWrapper>
            <AccordionContent show={active} copy={summary} />
        </Wrapper>
    );
};

Item.propTypes = {
    open: bool,
    title: string.isRequired,
    summary: string.isRequired
};

Item.defaultProps = {
    open: false
};

export default Item;
