import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { Button } from 'TMComponents/Button';
import { v4 as uuidv4 } from 'uuid';
import { getOneLinkURL } from '..';

const AppsFlyerButton = ({ text, ...rest }) => {
    const [href, setHref] = useState();

    useEffect(() => {
        /* istanbul ignore next */
        const observer = new MutationObserver((_, obs) => {
            if (window.AF_SMART_SCRIPT) {
                const clickId = uuidv4();

                const { clickURL } = getOneLinkURL(clickId);
                setHref(clickURL);
                obs.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true
        });

        return () => observer.disconnect();
    }, []);
    /* istanbul ignore next */
    return href ? (
        <Button {...rest} link={href}>
            {text}
        </Button>
    ) : null;
};

AppsFlyerButton.propTypes = {
    text: string
};

AppsFlyerButton.defaultProps = {
    text: ''
};

export default AppsFlyerButton;
export { AppsFlyerButton };
