import React from 'react';
import styled from 'styled-components';
import cardProps from 'TMPropTypes/cardProps';
import { breakpoints } from 'TMConstants';
import Img from 'TMComponents/ImageDynamic';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import ContentParserStructured from 'TMComponents/ContentParserStructured';

const Wrapper = styled.div`
    background-color: ${({ whiteCardBackground, theme }) =>
        whiteCardBackground && theme.colorSystem.primary[3]};
    border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
    border-radius: 10px;
    box-shadow: ${(props) =>
        props.shadow && props.theme.colorSystem.shadows[2]};
    margin-bottom: 16px;
    max-width: 370px;
    padding: 16px;
    padding-bottom: 32px;
    width: calc(100% - 16px);

    ${(props) =>
        props.height &&
        `
        flex: 0 0 350px;
        height: ${props.height}px;
        padding: 0;
        margin-right: 16px;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    `};

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 8px;
        width: calc(33.3% - 64px);
    }
`;

const Background = styled(BgImage)`
    height: 100%;
    &::before,
    &::after {
        border-radius: 10px;
    }
`;

const IconWrapper = styled.div`
    margin-bottom: -8px;
`;

const ContentWrapper = styled.div`
    bottom: 0;
    padding: 16px 16px 0;
    position: absolute;
    width: calc(100% - 40px);
`;

const Card = ({
    icon,
    contentStructured,
    shadow,
    backgroundImage,
    height,
    whiteCardBackground,
    centerAlignText
}) => {
    const content = (
        <>
            {icon && (
                <IconWrapper>
                    <Img imageData={icon.icon} />
                </IconWrapper>
            )}
            <ContentParserStructured
                structuredCopy={contentStructured}
                centerAlignText={centerAlignText}
            />
        </>
    );
    const gatsbyImg = getImage(backgroundImage);
    return (
        <Wrapper
            whiteCardBackground={whiteCardBackground}
            shadow={shadow}
            height={height}>
            {backgroundImage ? (
                <Background image={gatsbyImg}>
                    <ContentWrapper>{content}</ContentWrapper>
                </Background>
            ) : (
                content
            )}
        </Wrapper>
    );
};

Card.propTypes = cardProps;

export default Card;
