/* eslint-disable better-styled-components/sort-declarations-alphabetically */
import styled, { css } from 'styled-components';

export const Bold = css`
    font-weight: 700;
`;
export const SemiBold = css`
    font-weight: 600;
`;
export const Medium = css`
    font-weight: 500;
`;

/* istanbul ignore next */
export const Strong = css`
    strong {
        ${Bold}
    }
`;

/* istanbul ignore next */
export const Mark = css`
    mark {
        color: ${({ theme }) => theme.colorSystem.secondary[3]};
        background: none;
    }
`;

export const SmallText = css`
    font-size: 12px;
    line-height: 20px;
`;

export const MediumText = css`
    font-size: 16px;
    line-height: 24px;
`;

export const LargeText = css`
    font-size: 18px;
    line-height: 24px;
`;

export const CTA = css`
    ${Bold}
    ${LargeText}
`;

export const CategoryLabels = css`
    ${Medium}
    ${SmallText}
`;

export const Breadcrumbs = css`
    ${SemiBold}
    ${MediumText}
`;

export const Nav = css`
    ${Bold}
    ${MediumText}
`;

export const FooterTitles = css`
    ${SemiBold}
    ${MediumText}
`;

export const FooterContent = css`
    ${Medium}
    ${SmallText}
`;

export const FormFieldLabel = css`
    ${Medium}
    ${MediumText}
`;
export const FormFieldLabelSmall = css`
    ${Medium}
    ${SmallText}
`;
export const FormFieldInput = css`
    ${Medium}
    ${MediumText}
`;

const TMText = css`
    margin: 16px 0;
    letter-spacing: 0px;
`;

const TMHeading = css`
    ${TMText}
    ${Bold};
`;

export const H1Alt = styled.h1`
    ${TMHeading}
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1.5px;
`;
H1Alt.displayName = 'H1Alt';

export const H1 = styled.h1`
    ${TMHeading}
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
`;
H1.displayName = 'H1';

export const H2 = styled.h2`
    ${TMHeading}
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
`;
H2.displayName = 'H2';

export const H3 = styled.h3`
    ${TMHeading}
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
`;
H3.displayName = 'H3';

export const H4 = styled.h4`
    ${TMHeading}
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.5px;
`;
H4.displayName = 'H4';

export const H5 = styled.h5`
    ${TMHeading}
    font-size: 20px;
    line-height: 28px;
`;
H5.displayName = 'H5';

export const BodyLarge = styled.p`
    ${TMText}
    font-size: 20px;
    line-height: 28px;
`;
BodyLarge.displayName = 'BodyLarge';

export const Body = styled.p`
    ${TMText}
    font-size: 16px;
    line-height: 24px;
`;
Body.displayName = 'Body';

export const BodySmall = styled.p`
    ${TMText}
    font-size: 14px;
    line-height: 22px;
`;
BodySmall.displayName = 'BodySmall';

export const BodyExtraSmall = styled.p`
    ${TMText}
    font-size: 12px;
    line-height: 20px;
`;
BodyExtraSmall.displayName = 'BodyExtraSmall';
