import React from 'react';
import { string } from 'prop-types';
import { H4 } from 'TMComponents/Typography';
import ContentParser from 'TMComponents/ContentParser';

const Question = ({ title, summary }) => (
    <>
        <H4>{title}</H4>
        <ContentParser copy={summary} />
    </>
);

Question.propTypes = {
    title: string.isRequired,
    summary: string.isRequired
};

export default Question;
