import React from 'react';
import { string } from 'prop-types';

const Hamburger = ({ color, ...rest }) => (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" {...rest}>
        <rect y="0.888889" width="24" height="2" rx="1" fill={color} />
        <rect y="8.88889" width="24" height="2" rx="1" fill={color} />
        <rect y="16.8889" width="24" height="2" rx="1" fill={color} />
    </svg>
);

Hamburger.propTypes = {
    color: string
};

Hamburger.defaultProps = {
    color: '#003057'
};

export default Hamburger;
