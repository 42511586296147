/* eslint-disable global-require, no-case-declarations */
import { bool, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'TMConstants';

const Options = styled.div`
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
    overflow: hidden;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: ${(props) => (props.hideOnDesktop ? 'none' : 'block')};
    }

    ${({ whiteBackground, backgroundVariant, theme }) =>
        whiteBackground &&
        !backgroundVariant &&
        `background-color: ${theme.colorSystem.primary[3]};`}
    ${({ backgroundVariant }) =>
        backgroundVariant &&
        `
        background-color: ${backgroundVariant};
    `}
    ${({ noSideMargins }) =>
        noSideMargins &&
        `
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
`}
`;

const SharedOptions = ({
    hideOnMobile,
    hideOnDesktop,
    whiteBackground,
    backgroundVariant,
    noSideMargins,
    children
}) => {
    return (
        <Options
            whiteBackground={whiteBackground}
            hideOnMobile={hideOnMobile}
            hideOnDesktop={hideOnDesktop}
            noSideMargins={noSideMargins}
            backgroundVariant={backgroundVariant}>
            {children}
        </Options>
    );
};

SharedOptions.propTypes = {
    children: node.isRequired,
    hideOnMobile: bool,
    hideOnDesktop: bool,
    whiteBackground: bool,
    backgroundVariant: string,
    noSideMargins: bool
};

SharedOptions.defaultProps = {
    hideOnMobile: false,
    hideOnDesktop: false,
    whiteBackground: false,
    backgroundVariant: '',
    noSideMargins: false
};

export default SharedOptions;
