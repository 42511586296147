import React, { useEffect, useState } from 'react';
import { string, number, shape } from 'prop-types';
import axios from 'axios';
import LottieSpinner from 'TMComponents/LottieSpinner';
import Lottie from 'TMComponents/Lottie';

const LottieAsync = ({ lottieJson, maxWidth }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [lottieJSON, setLottieJSON] = useState();

    const getLottieJSON = async (url) => {
        const res = await axios.get(url);
        return res;
    };
    useEffect(() => {
        getLottieJSON(lottieJson.url)
            .then(({ data }) => {
                setLottieJSON(data);
                setIsLoading(false);
            })
            .catch(setIsLoading(true));
    }, []);

    if (isLoading) {
        return <LottieSpinner maxWidth={maxWidth} />;
    }

    return <Lottie maxWidth={maxWidth} code={lottieJSON} />;
};

LottieAsync.propTypes = {
    maxWidth: number,
    lottieJson: shape({ url: string }).isRequired
};

LottieAsync.defaultProps = {
    maxWidth: undefined
};

export default LottieAsync;
