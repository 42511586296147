import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { BodySmall } from 'TMComponents/Typography';
import { Button } from 'TMComponents/Button';
import { string } from 'prop-types';
import { useInView } from 'react-intersection-observer';

const Wrapper = styled(FlexWrapper)`
    background-color: ${({ theme }) => theme.colorSystem.secondary[2]};
    bottom: ${({ isVisible }) => (isVisible ? 0 : -300)}px;
    color: ${({ theme }) => theme.colorSystem.primary[3]};
    box-shadow: ${({ theme }) => theme.colorSystem.shadows[1]};
    left: 0;
    margin-bottom: 0px !important;
    padding: 12px 0 12px 0;
    position: fixed;
    right: 0;
    transition: bottom 0.5s ease-out;
    z-index: 1;
`;

const SubText = styled(BodySmall)`
    margin-bottom: 4px;
    margin-top: 6px;
`;

const areAllConcealersOffScreen = (concealerHub) => {
    const concealerRefs = Object.getOwnPropertySymbols(concealerHub);
    return concealerRefs.every((concealerRef) => {
        const isOffScreen = !concealerHub[concealerRef];
        return isOffScreen;
    });
};

const StickyFooterContext = React.createContext({
    concealerHub: {},
    setConcealerHub: null
});
const StickyFooterContextProvider = StickyFooterContext.Provider;

const StickyFooter = ({ buttonLink, buttonText, subText, testId }) => {
    const { concealerHub, setConcealerHub } = useContext(
        StickyFooterContext
    );
    const [isVisible, setIsVisible] = useState(!setConcealerHub); // Start visible if no visibility-altering context exists.

    useEffect(() => {
        const isVis = areAllConcealersOffScreen(concealerHub);
        setIsVisible(isVis);
    }, [concealerHub]);

    return (
        <Wrapper
            id="sticky-footer"
            direction="column"
            align="center"
            isVisible={isVisible}
            data-testid={testId}>
            <Button link={buttonLink} variant="secondary">
                {buttonText}
            </Button>
            {subText && <SubText>{subText}</SubText>}
        </Wrapper>
    );
};

const StickyFooterConcealer = ({ testId, ...otherProps }) => {
    const { concealerHub, setConcealerHub } = useContext(
        StickyFooterContext
    );

    if (setConcealerHub) {
        const [symbol] = useState(Symbol('ConcealerSymbol'));
        const [ref, inView] = useInView(otherProps);

        useEffect(
            () => () => {
                delete concealerHub[symbol];
                setConcealerHub({ ...concealerHub });
            },
            []
        );

        useEffect(() => {
            concealerHub[symbol] = inView;
            setConcealerHub({ ...concealerHub });
        }, [inView]);

        return (
            <div
                ref={ref}
                data-testid={testId}
                className="sticky-footer-concealer"
            />
        );
    }
    return null;
};

StickyFooter.propTypes = {
    buttonLink: string,
    buttonText: string,
    testId: string,
    subText: string
};

StickyFooter.defaultProps = {
    buttonLink: '',
    buttonText: '',
    testId: '',
    subText: ''
};

StickyFooterConcealer.propTypes = {
    testId: string
};

StickyFooterConcealer.defaultProps = {
    testId: ''
};
export {
    StickyFooter,
    StickyFooterConcealer,
    StickyFooterContextProvider
};
