import { string, shape, number, arrayOf } from 'prop-types';

const gatsbyImageDataProps = {
    width: number,
    height: number,
    images: shape({
        sources: arrayOf(string),
        fallback: shape({
            src: string
        })
    })
};

export default {
    ...gatsbyImageDataProps
};
