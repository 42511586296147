import React from 'react';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { string } from 'prop-types';

const CustomHTML = ({ html }) => {
    return (
        <FlexWrapper
            justify="center"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

CustomHTML.propTypes = {
    html: string
};

export default CustomHTML;
