/* eslint-disable react/prop-types */
import { object, shape, bool, number } from 'prop-types';
import imageProps from './imageProps';

export default {
    icon: shape({
        icon: shape(imageProps)
    }),
    shadow: bool,
    backgroundImage: shape(imageProps),
    height: number,
    whiteCardBackground: bool,
    contentStructured: object
};
