import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { BgImage } from 'gbimage-bridge';
import { string, bool, shape } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';

const CoverImage = styled(BgImage)`
    aspect-ratio: 1;
    width: 100%;
`;

const ImageDynamic = ({ imageData, isCover, alt, ...rest }) => {
    const gatsbyImg = getImage(imageData);
    const altText = imageData?.alt || alt;
    if (isCover) {
        return (
            <CoverImage
                alt={altText}
                image={gatsbyImg}
                placeholder="none"
                backgroundColor="transparent"
                {...rest}
            />
        );
    }
    return (
        <GatsbyImage
            placeholder="none"
            backgroundColor="transparent"
            alt={alt}
            image={gatsbyImg}
            {...rest}
            objectPosition="left bottom"
        />
    );
};

export default ImageDynamic;

ImageDynamic.propTypes = {
    imageData: shape(imageProps).isRequired,
    isCover: bool,
    alt: string
};

ImageDynamic.defaultProps = {
    isCover: false,
    alt: ''
};
