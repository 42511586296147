import React from 'react';
import { bool, node, string } from 'prop-types';
import styled from 'styled-components';

const StyledList = styled.ul`
    margin-bottom: 16px;
    margin-top: 0;
    padding-inline-start: 25px;
    text-align: left;
    width: fit-content;
    display: flex;
    flex-direction: column;
    p {
        margin-bottom: 0;
        margin-top: 0;
    }
    ${({ centerAlignText }) => centerAlignText && `margin: auto;`}
    ${({ markerStyle }) =>
        markerStyle === 'numbered' && 'list-style-type: number;'}
    ${({ listCheckMarks }) =>
        listCheckMarks &&
        `
            padding-inline-start: 0;
            gap:12px;
        `}
`;

const List = ({
    markerStyle,
    listCheckMarks,
    centerAlignText,
    children
}) => (
    <StyledList
        markerStyle={markerStyle}
        listCheckMarks={listCheckMarks}
        centerAlignText={centerAlignText}>
        {children}
    </StyledList>
);

List.propTypes = {
    children: node.isRequired,
    markerStyle: string,
    listCheckMarks: bool,
    centerAlignText: bool
};
List.defaultProps = {
    markerStyle: 'bulleted',
    listCheckMarks: false,
    centerAlignText: false
};
export default List;
