/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { arrayOf, node, number } from 'prop-types';

import FlexWrapper from 'TMComponents/FlexWrapper';
import { breakpoints } from 'TMConstants';

const CompWrapper = styled.div`
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    overflow-x: auto;
    padding: 32px 0;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: ${breakpoints.tabletDesktop}px) {
        justify-content: center;
    }
`;

const CircleWrapper = styled(FlexWrapper)`
    @media (min-width: ${breakpoints.tabletDesktop}px) {
        display: none;
    }
`;

const Circle = styled.div`
    background-color: ${({ theme, isActive }) =>
        isActive
            ? theme.colorSystem.secondary[1]
            : theme.colorSystem.secondary[2]};
    border: 2px solid ${({ theme }) => theme.colorSystem.primary[1]};
    border-radius: 50%;
    height: 16px;
    margin-right: 8px;
    width: 16px;

    &:last-child {
        margin-right: none;
    }
`;

const HorizontalScroll = ({ children, maxWidth }) => {
    const [inView, updateInView] = useState(0);
    return (
        <>
            <CompWrapper
                onScroll={(e) => {
                    updateInView(
                        Math.round(e.target.scrollLeft / maxWidth)
                    );
                }}
                data-testid="wrapper">
                {children}
            </CompWrapper>
            <CircleWrapper justify="center">
                {[...Array(children.length).keys()].map((_, i) => (
                    <Circle
                        key={i}
                        isActive={i === inView}
                        data-testid={`circle-${i}`}
                    />
                ))}
            </CircleWrapper>
        </>
    );
};

HorizontalScroll.propTypes = {
    children: arrayOf(node).isRequired,
    maxWidth: number.isRequired
};

export default HorizontalScroll;
