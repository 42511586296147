/* eslint-disable camelcase */
/* istanbul ignore file */
// Initializing Smart Script arguments
const oneLinkURL = process.env.GATSBY_APPSFLYER_ONELINK_URL;
const mediaSource = { keys: ['source'], defaultValue: 'utm_source' };
const campaign = { keys: ['campaign'] };
const site_id = { paramKey: 'af_siteid', keys: ['site_id'] };
const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };

// Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
// Onelink URL is generated.
export const getOneLinkURL = (clickId = '') => {
    return window?.AF_SMART_SCRIPT?.generateOneLinkURL({
        oneLinkURL,
        afParameters: {
            mediaSource,
            campaign,
            afCustom: [site_id, custom_ss_ui],
            ...(clickId && {
                afSub1: {
                    keys: ['click_id'],
                    defaultValue: clickId
                }
            })
        }
    });
};

export const displayQRCode = (divId, clickId) => {
    getOneLinkURL(clickId);
    window?.AF_SMART_SCRIPT?.displayQrCode(divId);
};
