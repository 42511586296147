/* eslint-disable global-require */

const dictionary = {
    DatoCmsAccountComparison: require('TMComponents/AccountComparison')
        .default,
    DatoCmsAccountFeature: require('TMComponents/AccountFeature').default,
    DatoCmsAppsFlyerButton: require('TMComponents/AppsFlyer/Button')
        .default,
    DatoCmsQrCode: require('TMComponents/AppsFlyer/QRCode').default,
    DatoCmsAppStoreLink: require('TMComponents/AppStoreLink').default,
    DatoCmsAppStoreLink__component_group:
        require('TMComponents/AppStoreLink').AppStoreLinkGroup,
    DatoCmsButton: require('TMComponents/Button').default,
    DatoCmsButton__component_group: require('TMComponents/Button')
        .ButtonGroup,
    DatoCmsCard__component_group: require('TMComponents/CardContainer')
        .default,
    DatoCmsCardV2__component_group: require('TMComponents/CardContainer')
        .default,
    DatoCmsInfoCard__component_group: require('TMComponents/CardContainer')
        .default,
    DatoCmsCurrencyConverter: require('TMComponents/CurrencyConverter')
        .default,
    DatoCmsCustomHtml: require('TMComponents/CustomHTML').default,
    DatoCmsLottie: require('TMComponents/LottieAsync').default,
    DatoCmsSendAppLink: require('TMComponents/SMS').default,
    DatoCmsColumnTitle: require('TMComponents/ColumnTitle').default,
    DatoCmsColumnLink: require('TMComponents/ColumnLink').default,
    DatoCmsFaq: require('TMComponents/FAQ').default,
    DatoCmsIconBlock: require('TMComponents/Icon').default,
    DatoCmsIconWithContent: require('TMComponents/IconWithContent')
        .default,
    DatoCmsImage: require('TMComponents/Image').default,
    DatoCmsImageList: require('TMComponents/ImageList').default,
    DatoCmsImageWithContent: require('TMComponents/ImageWithContent')
        .default,
    DatoCmsMoreInformation: require('TMComponents/MoreInformation')
        .default,
    DatoCmsOtCookieList: require('TMComponents/OtCookieList').default,
    DatoCmsPanel: require('TMComponents/Panel').default,
    DatoCmsRepExample: require('TMComponents/RepExample').default,
    DatoCmsSeparator: require('TMComponents/Separator').default,
    DatoCmsSocialMediaLink: require('TMComponents/SocialMediaLink')
        .default,
    DatoCmsStickyFooter: require('TMComponents/StickyFooter').StickyFooter,
    DatoCmsStickyFooterConcealer: require('TMComponents/StickyFooter')
        .StickyFooterConcealer,
    DatoCmsTable: require('TMComponents/Table').default,
    DatoCmsText: require('TMComponents/ContentParser').default,
    DatoCmsTextStructured: require('TMComponents/ContentParserStructured')
        .default,
    DatoCmsTrustpilotCarousel: require('TMComponents/TrustpilotCarousel')
        .default,
    DatoCmsTrustpilotReview__component_group:
        require('TMComponents/TrustpilotReview').TrustpilotReviewGroup,
    DatoCmsVideo: require('TMComponents/Video').default
};

export default dictionary;
