/* eslint-disable import/no-unresolved */

import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { breakpoints } from 'TMConstants';
import { node } from 'prop-types';

const Embla = styled.section`
    postion: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    --slide-height: 19rem;
    --slide-spacing: 0px;
    --slide-size: 100%;
    @media (min-width: ${breakpoints.tabletDesktop}px) {
        --slide-size: 29%;
    }
`;

const EmblaViewport = styled.div`
    overflow: hidden;
    max-width: calc(var(--slide-size) * 3 + 20);
    padding: 0 20px;
`;

const EmblaContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
`;

const EmblaSlide = styled.div`
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    user-select: none;
`;

const CircleWrapper = styled(FlexWrapper)`
    padding: 20px;
`;

const Circle = styled.div`
    border: 2px solid ${({ theme }) => theme.colorSystem.primary[1]};

    background-color: ${({ theme }) => theme.colorSystem.secondary[2]};
    &.selected {
        background-color: ${({ theme }) => theme.colorSystem.secondary[1]};
    }

    border-radius: 50%;
    height: 16px;
    margin-right: 8px;
    width: 16px;

    &:last-child {
        margin-right: none;
    }
`;

export const useSelectedSnapDisplay = (emblaApi) => {
    const [selectedSnap, setSelectedSnap] = useState(0);
    const [snapCount, setSnapCount] = useState(0);

    const updateScrollSnapState = useCallback((api) => {
        setSnapCount(api.scrollSnapList().length);
        setSelectedSnap(api.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;
        updateScrollSnapState(emblaApi);
        emblaApi.on('select', updateScrollSnapState);
        emblaApi.on('reInit', updateScrollSnapState);
    }, [emblaApi, updateScrollSnapState]);

    return {
        selectedSnap,
        snapCount
    };
};

const Carousel = ({ children, ...rest }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
        Autoplay()
    ]);

    const { selectedSnap } = useSelectedSnapDisplay(emblaApi);

    return (
        <Embla {...rest}>
            <EmblaViewport ref={emblaRef}>
                <EmblaContainer>
                    {children.map((child) => (
                        <EmblaSlide key={child.key}>{child}</EmblaSlide>
                    ))}
                </EmblaContainer>
                <CircleWrapper justify="center">
                    {children.map((_, i) => (
                        <Circle
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            className={i === selectedSnap && 'selected'}
                            data-testid={`circle-${i}`}
                        />
                    ))}
                </CircleWrapper>
            </EmblaViewport>
        </Embla>
    );
};

export default Carousel;

Carousel.propTypes = {
    children: node.isRequired
};
