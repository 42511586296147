import React, { useRef } from 'react';
import styled from 'styled-components';
import { string, func, bool } from 'prop-types';
import Icon from 'TMComponents/Icons/Search';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import isEnterKeyPressed from '../../../utils/isEnterKeyPressed';
import {
    FormFieldInput,
    FormFieldLabel,
    FormFieldLabelSmall
} from '../../Typography';

const Wrapper = styled.div`
    position: relative;
    input:focus + label {
        ${FormFieldLabelSmall}
        top: 10px;
    }
    ${(isSearch) => isSearch && `min-width: 300px;`}
    ${(isSearch) => isSearch && `max-width: 360px;`}
`;

const StyledInput = styled.input`
    ${FormFieldInput}
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    padding: 29px 18px 11px;
    position: relative;
    text-align: left;
    width: calc(100% - 40px);
    box-shadow: inset ${({ theme }) => theme.colorSystem.shadows['6']};
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${({ theme }) => theme.colors.greys.light};
    }
`;
const Label = styled.label`
    ${FormFieldLabel}
    color: ${({ theme }) => theme.colorSystem.UIText[2]};
    cursor: text;
    left: 18px;
    position: absolute;
    top: 35%;
    transition: all 0.25s;
    ${({ hasContent }) => hasContent && 'top: 10px;'}
    ${({ hasContent }) => hasContent && FormFieldLabelSmall}
`;

const Search = styled(Icon)`
    position: absolute;
    right: 18px;
    top: 40%;
`;

const Input = ({
    placeholder,
    value,
    onChange,
    onKeyUp,
    search,
    isHelpAndSupport,
    ...rest
}) => {
    const inputEl = useRef(null);
    const focus = () => {
        inputEl.current.focus();
    };
    return (
        <Wrapper isSearch={search}>
            <StyledInput
                {...rest}
                ref={inputEl}
                id={placeholder}
                value={value}
                onChange={(e) => {
                    focus(e);
                    onChange(e);
                }}
                onKeyPress={focus}
                onClick={focus}
                onKeyUp={(e) => {
                    onKeyUp(e);
                }}
            />
            <Label hasContent={value.length > 0} htmlFor={placeholder}>
                {placeholder}
            </Label>
            {search &&
                (isHelpAndSupport ? (
                    <AnchorLink
                        to="/help-support#article-help"
                        title="Help and Support Articles">
                        <Search className="article-help" />
                    </AnchorLink>
                ) : (
                    <Search />
                ))}
        </Wrapper>
    );
};

Input.propTypes = {
    placeholder: string.isRequired,
    value: string.isRequired,
    onChange: func.isRequired,
    onKeyUp: func,
    search: bool,
    isHelpAndSupport: bool,
    type: string
};

Input.defaultProps = {
    onKeyUp: isEnterKeyPressed,
    search: false,
    isHelpAndSupport: false,
    type: 'text'
};

export default Input;
