export default (search) =>
    search
        .slice(1)
        .split('&')
        .reduce((obj, next) => {
            const [key, value] = next.split('=');
            const isSnakeCase = key.includes('_');

            let camelCaseKey = key;
            if (camelCaseKey.charAt(0) === '_') {
                camelCaseKey = camelCaseKey.substr(1);
            }
            if (isSnakeCase) {
                camelCaseKey = camelCaseKey
                    .split('_')
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join('');
                camelCaseKey =
                    camelCaseKey[0].toLowerCase() + camelCaseKey.slice(1);
            }
            return { ...obj, [camelCaseKey]: value || true };
        }, {});
