import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { bool } from 'prop-types';
import debounce from 'TMUtils/debounce';
import { breakpoints } from 'TMConstants';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export const isTopCheck = (isTop, updateIsTop) => {
    if (window.pageYOffset > 0 && isTop) {
        updateIsTop(false);
    }

    if (window.pageYOffset === 0 && !isTop) {
        updateIsTop(true);
    }
};

const Header = ({ hideNav }) => {
    const { header, banner } = useStaticQuery(graphql`
        {
            banner: datoCmsSiteBanner {
                showBanner
                backgroundPrimaryColour {
                    hex
                }
                image {
                    gatsbyImageData(
                        width: 50
                        layout: FIXED
                        imgixParams: { auto: "compress" }
                    )
                }
                structuredContent {
                    value
                }
                textColour {
                    hex
                }
                showButton
                buttonText
                buttonLink
                desktopButtonLink
            }
            header: datoCmsHeader {
                logo {
                    alt
                    gatsbyImageData(layout: CONSTRAINED)
                }
                desktopRightSection {
                    ...buttonFields
                }
                mobileBottomSection {
                    ...appsFlyerButtonFields
                    ...buttonFields
                    ... on DatoCmsImage {
                        __typename
                        id: originalId
                        image {
                            gatsbyImageData(
                                width: 350
                                layout: CONSTRAINED
                                imgixParams: { auto: "compress" }
                            )
                        }
                        maxWidth
                        link
                        whiteBackground
                        hideOnDesktop
                        hideOnMobile
                    }
                }
                menuItems {
                    ... on DatoCmsHeaderMenuItem {
                        __typename
                        id
                        title
                        url
                        submenu {
                            ... on DatoCmsSubmenuLink {
                                __typename
                                id
                                text
                                link
                            }
                        }
                    }
                }
            }
        }
    `);

    const [viewportWidth, setViewportWidth] = useState(null);
    const [isTop, updateIsTop] = useState(true);

    useEffect(() => {
        /* istanbul ignore next */
        if (window.location.href.includes('#') && isTop) {
            updateIsTop(false);
        }
    }, []);

    /* istanbul ignore next */
    const isTopHandler = () => {
        isTopCheck(isTop, updateIsTop);
    };

    const onResizeHandler = () => {
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('scroll', isTopHandler);
        return () => {
            window.removeEventListener('scroll', isTopHandler);
        };
    }, [isTop]);

    useEffect(() => {
        if (!viewportWidth) setViewportWidth(window.innerWidth);
        window.addEventListener('resize', debounce(onResizeHandler, 100));
        return () => {
            window.removeEventListener(
                'resize',
                debounce(onResizeHandler, 100)
            );
        };
    }, [viewportWidth]);

    const { logo, ...rest } = header;

    const headerProps = {
        ...rest,
        isTop,
        hideNav
    };

    return (
        <header>
            {viewportWidth < breakpoints.laptop ? (
                <MobileHeader
                    {...headerProps}
                    logo={logo}
                    banner={banner}
                />
            ) : (
                <DesktopHeader
                    {...headerProps}
                    logo={logo}
                    banner={banner}
                />
            )}
        </header>
    );
};

Header.propTypes = {
    hideNav: bool
};

Header.defaultProps = {
    hideNav: false
};

export default Header;
