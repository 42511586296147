import React from 'react';
import { node, bool } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    padding-inline-start: 0;
    ${(props) =>
        props.justify &&
        `
        justify-content: ${props.justify};
    `}

    ${(props) =>
        props.align &&
        `
        align-items: ${props.align};
    `}

    ${(props) =>
        props.direction &&
        `
        flex-direction: ${props.direction};
    `}

    ${(props) =>
        props.wrap &&
        `
        flex-wrap: wrap;
    `}
`;

const FlexWrapper = ({ children, wrap, ...rest }) => (
    <Wrapper wrap={wrap && wrap.toString()} {...rest}>
        {children}
    </Wrapper>
);

FlexWrapper.propTypes = {
    children: node.isRequired,
    wrap: bool
};

FlexWrapper.defaultProps = {
    wrap: undefined
};

export default FlexWrapper;
