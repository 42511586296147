import React from 'react';
import styled from 'styled-components';
import NavLink from 'TMComponents/NavLink';
import Facebook from 'TMComponents/Icons/Facebook';
import Twitter from 'TMComponents/Icons/Twitter';
import Youtube from 'TMComponents/Icons/Youtube';
import { string, oneOf } from 'prop-types';

const Link = styled(NavLink)`
    margin-left: -8px;
    padding-right: 16px;
`;

const SocialMediaLink = ({ link, company, size = 'md' }) => {
    const iconMap = {
        facebook: Facebook,
        twitter: Twitter,
        youtube: Youtube
    };

    const Icon = iconMap[company];
    if (!Icon) {
        return null;
    }
    return (
        <Link href={link}>
            <Icon width={size === 'lg' ? 68 : 56} />
        </Link>
    );
};
SocialMediaLink.propTypes = {
    link: string.isRequired,
    company: string.isRequired,
    size: oneOf(['md', 'lg'])
};

export default SocialMediaLink;
