import React from 'react';
import { string, bool, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import FAQCategory from 'TMComponents/FAQCategory';

const MoreInformationWrapper = styled.div`
    max-width: 568px;
    div:last-child {
        border-bottom: none;
    }
`;

const MoreInformation = ({ categories, categoryAlternativeColor }) => (
    <MoreInformationWrapper>
        {categories.map(({ id, ...rest }) => (
            <FAQCategory
                key={id}
                categoryAlternativeColor={categoryAlternativeColor}
                {...rest}
            />
        ))}
    </MoreInformationWrapper>
);

MoreInformation.propTypes = {
    categories: arrayOf(
        shape({
            id: string,
            name: string,
            nameLink: string,
            posts: arrayOf(
                shape({
                    name: string,
                    url: string
                })
            )
        })
    ).isRequired,
    categoryAlternativeColor: bool
};

MoreInformation.defaultProps = {
    categoryAlternativeColor: false
};

export default MoreInformation;
