export default (node) => {
    if (node?.value) {
        const children = node?.value?.document.children;
        const grandchildren = children[0]?.children;

        return (
            children.length === 1 &&
            grandchildren.length === 1 &&
            grandchildren[0].value === ''
        );
    }
    if (node?.children) {
        const { children } = node;

        return children.length === 1 && children[0].value === '';
    }
    return true;
};
