import React from 'react';
import Img from 'TMComponents/ImageDynamic';
import NavLink from 'TMComponents/NavLink';
import { shape, string, number, arrayOf } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';
import FlexWrapper from 'TMComponents/FlexWrapper';
import styled from 'styled-components';
import { breakpoints } from 'TMConstants';

const AppstoreLinkGroupWrapper = styled(FlexWrapper)`
    max-width: 568px;
    a {
        margin-bottom: 16px;
    }
    a:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.mobileTablet}px) {
        flex-direction: row;
        a {
            margin-right: 16px;
            margin-bottom: 0px;
        }
        a:last-child {
            margin-right: 0;
        }
    }
`;

const AppStoreLink = ({ image, link, height }) => {
    const newImage = { ...image };
    const aspectRatio =
        newImage.gatsbyImageData.width / newImage.gatsbyImageData.height;
    newImage.gatsbyImageData.height = height;
    newImage.gatsbyImageData.width = height * aspectRatio;

    return (
        <NavLink href={link} aria-label={newImage?.alt}>
            <Img
                imageData={newImage}
                objectFit="contain"
                loading="eager"
            />
        </NavLink>
    );
};

const AppStoreLinkGroup = ({ components }) => (
    <AppstoreLinkGroupWrapper
        direction="column"
        align="center"
        justify="center">
        {components.map((subItem) => (
            <AppStoreLink key={subItem.id} {...subItem} />
        ))}
    </AppstoreLinkGroupWrapper>
);

AppStoreLink.propTypes = {
    link: string.isRequired,
    image: shape(imageProps).isRequired,
    height: number
};

AppStoreLink.defaultProps = {
    height: 60
};
AppStoreLinkGroup.propTypes = {
    components: arrayOf(
        shape({
            link: string.isRequired,
            image: shape(imageProps).isRequired,
            height: number
        })
    ).isRequired
};

export default AppStoreLink;
export { AppStoreLink, AppStoreLinkGroup };
