/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { array, bool, string } from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { themeObj } from '../GlobalStyle';
import Checkmark from '../Icons/Checkmark';
import Remove from '../Icons/Remove';
import Content from '../Content';

const panelBg = {
    success: themeObj.colorSystem.secondary['5'],
    fail: themeObj.colorSystem.validations['4']
};

const Wrapper = styled.div`
    ${({ fullWidth }) => (fullWidth ? 'width:100%' : 'max-width: 390px')};
    ${({ leftAlign }) => leftAlign && 'margin-left: 0px !important;'};
    border-radius: 16px;
    padding: 16px;
    background-color: ${({ variant }) => panelBg[variant]};
`;

const IconWrapper = styled.div`
    display: ${({ displayIcon }) =>
        displayIcon ? 'inline-block' : 'none'};
    width: 24px;
    height: 24px;
    padding-right: 8px;
    vertical-align: middle;
`;

const Title = styled.h5`
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
`;

const Panel = ({
    displayIcon,
    fullWidth,
    leftAlign,
    variant,
    title,
    content
}) => {
    const theme = useTheme();
    return (
        <Wrapper
            variant={variant}
            fullWidth={fullWidth}
            leftAlign={leftAlign}>
            <div>
                <IconWrapper displayIcon={displayIcon}>
                    {variant === 'success' && (
                        <Checkmark
                            fill={theme.colorSystem.validations['1']}
                        />
                    )}
                    {variant === 'fail' && (
                        <Remove
                            fill={theme.colorSystem.validations['2']}
                        />
                    )}
                </IconWrapper>
                <Title>{title}</Title>
            </div>
            <Content
                backgroundVariant={variant}
                noSidePadding
                content={content}
            />
        </Wrapper>
    );
};

Panel.propTypes = {
    variant: string,
    fullWidth: bool,
    leftAlign: bool,
    displayIcon: bool,
    title: string,
    // eslint-disable-next-line react/forbid-prop-types
    content: array
};

Panel.defaultProps = {
    variant: 'success',
    fullWidth: false,
    leftAlign: false,
    displayIcon: true,
    title: '',
    content: []
};

export default Panel;
