import React, { useState } from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from 'TMComponents/Breadcrumbs';
import Container from 'TMComponents/Container';
import FlexWrapper from 'TMComponents/FlexWrapper';
import NavLink from 'TMComponents/NavLink';
import Img from 'TMComponents/ImageStatic';
import { Banner } from 'TMComponents/Banner';
import headerProps from 'TMPropTypes/headerProps';
import ComponentMapper from 'TMComponents/ComponentMapper';
import MenuItem from './MenuItem';

const Wrapper = styled.div`
    display: block;
    position: ${(props) => (props.hideNav ? 'absolute' : 'fixed')};
    top: 0;
    width: 100%;
    z-index: 15;
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
`;

const ContainerWithBorder = styled(Container)`
    ${({ isTop }) =>
        isTop && 'border-bottom: 1px solid rgba(255, 255, 255, 0.1)'};
    ${({ hideNav }) => hideNav && 'padding-top: 16px'};
    max-width: 1280px;
    height: 120px;
    display: flex;
`;

const MenuItemList = styled(FlexWrapper)`
    display: flex;
    list-style: none;
    padding: 0;
    padding-left: 20px;
    padding-right: 40px;
    color: ${({ theme }) => theme.colorSystem.primary[1]};
`;

const DesktopHeader = ({
    logo,
    menuItems,
    desktopRightSection = [],
    isTop,
    hideNav,
    banner
}) => {
    const [hoveringOver, setHoveringOver] = useState(null);

    const isRightSectionButton =
        desktopRightSection.length !== 0 &&
        (desktopRightSection[0].__typename === 'DatoCmsButton' ||
            desktopRightSection[0].__typename ===
                'DatoCmsAppsFlyerButton');

    const RightSectionComponent = isRightSectionButton
        ? { ...desktopRightSection[0], ...{ size: 'small' } }
        : desktopRightSection[0];

    return (
        <Wrapper hideNav={hideNav}>
            {banner?.showBanner && <Banner {...banner} />}
            <ContainerWithBorder isTop={isTop} hideNav={hideNav}>
                <FlexWrapper as="nav" align="center">
                    <NavLink href="/">
                        <Img
                            imageData={logo}
                            width="180"
                            style={{ zIndex: 10 }}
                            loading="eager"
                        />
                    </NavLink>
                    {!hideNav && (
                        <MenuItemList as="ul" flex-direction="row">
                            {menuItems.map(
                                ({ id, title, url, submenu }) => {
                                    const itemIsActive =
                                        id === hoveringOver;
                                    return (
                                        <MenuItem
                                            as="li"
                                            key={`${id}`}
                                            title={title}
                                            url={url}
                                            submenu={submenu}
                                            isActive={itemIsActive}
                                            onHover={() =>
                                                setHoveringOver(id)
                                            }
                                            onHoverStop={() =>
                                                setHoveringOver(null)
                                            }
                                        />
                                    );
                                }
                            )}
                        </MenuItemList>
                    )}
                    {!hideNav && (
                        <ComponentMapper {...RightSectionComponent} />
                    )}
                </FlexWrapper>
            </ContainerWithBorder>
            <Breadcrumbs />
        </Wrapper>
    );
};

DesktopHeader.propTypes = headerProps;

export default DesktopHeader;
