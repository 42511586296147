import { number } from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Youtube = ({ width = 56 }) => {
    const { colorSystem } = useContext(ThemeContext);

    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd)">
                <path
                    d="M29.8487 49.6982C18.9047 49.6982 10 40.7942 10 29.8495C10 18.904 18.9047 10 29.8487 10C40.7942 10 49.6982 18.904 49.6982 29.8495C49.6982 40.7942 40.7942 49.6982 29.8487 49.6982Z"
                    fill={colorSystem.primary[1]}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.392 30.2495L28.1193 33.1418C27.8909 33.2655 27.6851 33.0996 27.6851 32.8393V26.9025C27.6851 26.6393 27.8975 26.4735 28.1265 26.6036L33.4356 29.648C33.6698 29.7811 33.6305 30.1207 33.392 30.2495ZM41.7337 26.8393C41.7337 24.024 39.4515 21.7418 36.6363 21.7418H23.416C20.6007 21.7418 18.3185 24.024 18.3185 26.8393V33.0356C18.3185 35.8509 20.6007 38.1331 23.416 38.1331H36.6363C39.4515 38.1331 41.7337 35.8509 41.7337 33.0356V26.8393V26.8393Z"
                fill={colorSystem.primary[3]}
            />
            <defs>
                <filter
                    id="filter0_dd"
                    x="0"
                    y="0"
                    width="55.6982"
                    height="55.6982"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.647382 0 0 0 0 0.907473 0 0 0 0 0.951483 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Youtube;

Youtube.propTypes = {
    width: number
};
