/* eslint global-require: "error" */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { object, string } from 'prop-types';
import axios from 'axios';
import Container from 'TMComponents/Container';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Dropdown from 'TMComponents/form/Dropdown';
import Table from 'TMComponents/Table';
import LottieSpinner from 'TMComponents/LottieSpinner';
import ContentParserStructured from 'TMComponents/ContentParserStructured';
import { Body } from 'TMComponents/Typography';
import { format, parseISO } from 'date-fns';

const LastUpdated = styled(Body)`
    margin: 0px;
    padding: 40px 0px 24px;
`;

const ErrorMessage = styled.div`
    padding: 100px 0;
`;

export const modelTableBody = (currency) => {
    if (currency) {
        return [
            [
                `1 GBP = ${
                    Math.round(
                        (1 / currency.ecbReferenceRate) * 10000000
                    ) / 10000000
                } ${currency.currencyAlpha}`,
                `1 GBP = ${
                    Math.round((1 / currency.mastercardRate) * 10000000) /
                    10000000
                } ${currency.currencyAlpha}`,
                `${currency.totalmarkupOverECBPct}%`
            ]
        ];
    }
    return [];
};

export const formatDisplayDate = (date) => {
    if (date) {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'do MMMM yyyy');
    }
    return 'Invalid Date';
};

export const modelCurrencyList = (currencies) => {
    if (Array.isArray(currencies)) {
        return currencies.map((currency) => ({
            ...currency,
            key: currency.currencyAlpha,
            value: `${currency.displayedCurrencyName} (${currency.currencyAlpha})`
        }));
    }
    return [];
};

export const filterSortCurrencyList = (currencies, orderedList) => {
    const bothListsAreNotEmpty = !!(
        currencies.length && orderedList.length
    );
    const bothAreArrays = !!(
        Array.isArray(currencies) && Array.isArray(orderedList)
    );

    if (bothListsAreNotEmpty && bothAreArrays) {
        const filteredCurrencies = orderedList.reduce(
            (accumulator, currentValue) => {
                const currencyFound = currencies.find(
                    (currency) => currency.currencyAlpha === currentValue
                );
                if (
                    currencyFound &&
                    accumulator.indexOf(currencyFound) === -1
                ) {
                    accumulator.push(currencyFound);
                }
                return accumulator;
            },
            []
        );

        return filteredCurrencies;
    }
    return [];
};

export const getLatestCurrencies = () => {
    return axios.get(process.env.GATSBY_FXRATES_URL, {
        headers: {
            'x-api-key': process.env.GATSBY_FXRATES_API_KEY
        }
    });
};

export const fetchLatestCurrencies = (orderedCurrencyList) =>
    getLatestCurrencies()
        .then((res) => {
            const { data } = res;
            const filteredCurrencies = filterSortCurrencyList(
                data,
                orderedCurrencyList
            );
            const currencies = modelCurrencyList(filteredCurrencies);
            return currencies;
        })
        .catch((err) => err);

const CurrencyConverter = ({
    tableHeaders,
    errorCopyStructured,
    currencyOrder
}) => {
    const [dateUpdated, setDateUpdated] = useState('');
    const [currencyRates, setCurrencyRates] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(true);

    const orderedCurrencyList = currencyOrder.split('\n');
    useEffect(() => {
        fetchLatestCurrencies(orderedCurrencyList)
            .then((currencies) => {
                if (currencies.length) {
                    setCurrencyRates(currencies);
                    setDateUpdated(currencies[0].requestDate);
                    setSelectedCurrency(currencies[0]);
                    setLoading(false);
                } else {
                    setError('Could not retrieve currency data');
                    setLoading(false);
                }
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return (
        <Container data-testid="currency-converter-wrapper">
            {isLoading && (
                <LottieSpinner maxWidth={300} data-testid="loading" />
            )}
            {error && (
                <ErrorMessage>
                    <ContentParserStructured
                        structuredCopy={errorCopyStructured}
                    />
                </ErrorMessage>
            )}
            {!isLoading && !error && (
                <FlexWrapper direction="column" align="center">
                    <Dropdown
                        value={selectedCurrency.value}
                        optionList={currencyRates}
                        onItemSelect={setSelectedCurrency}
                    />
                    <LastUpdated>
                        Last updated: {formatDisplayDate(dateUpdated)}
                    </LastUpdated>
                    <Table
                        data={{
                            headings: tableHeaders.split('|'),
                            body: modelTableBody(selectedCurrency)
                        }}
                        className="currencyExchange"
                        hasUniformWidth
                    />
                </FlexWrapper>
            )}
        </Container>
    );
};

CurrencyConverter.propTypes = {
    tableHeaders: string.isRequired,
    errorCopyStructured: object.isRequired,
    currencyOrder: string.isRequired
};

export { CurrencyConverter as Unwrapped };
export default CurrencyConverter;
