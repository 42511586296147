import React, { useState, useEffect, useCallback } from 'react';
import { node, string, bool, func, arrayOf, shape } from 'prop-types';
import queryParamsToObj from 'TMUtils/queryParamsToObj';
import styled from 'styled-components';
import stringSwitch from 'TMUtils/stringSwitch';
import NavLink from 'TMComponents/NavLink';
import { breakpoints } from 'TMConstants';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { CTA } from '../Typography';

const PrimaryButton = styled.button`
    ${CTA}
    background-color: ${({ theme }) => theme.colorSystem.cta[1]};
    border: none;
    border-radius: 30px;
    color: ${({ theme }) => theme.colorSystem.UIText[3]};
    cursor: pointer;
    padding: 20px 40px;
    ${({ size }) => size === 'small' && 'padding: 16px 32px;'}
    position: relative;
    width: 100%;
    max-width: 350px;
    transition: all 0.3s;
    transition-property: background-color;

    &:hover,
    &:active {
        background: ${({ theme }) => theme.colorSystem.cta[2]};
    }
    white-space: normal;
`;

const SecondaryButton = styled(PrimaryButton)`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border-radius: 36px;
    border: solid 3px ${({ theme }) => theme.colorSystem.cta[1]};
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    transition-property: border-color;

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.colorSystem.primary[3]};
        border-color: ${({ theme }) => theme.colorSystem.cta[2]};
    }
`;

const PrimaryInvertedButton = styled(SecondaryButton)`
    border: none;
    transition-property: background-color;

    &:hover,
    &:active {
        background-color: ${({ theme }) =>
            theme.colorSystem.UIElements[4]};
    }
`;

const SecondaryInvertedButton = styled(SecondaryButton)`
    background-color: ${({ theme }) => theme.colorSystem.primary[1]};
    border-radius: 36px;
    border: solid 3px ${({ theme }) => theme.colorSystem.primary[3]};
    color: ${({ theme }) => theme.colorSystem.UIText[3]};
    transition-property: border-color, color;

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.colorSystem.primary[1]};
        border-color: ${({ theme }) => theme.colorSystem.UIElements[4]};
        color: ${({ theme }) => theme.colorSystem.UIElements[4]};
    }
`;

const SMSButton = styled(PrimaryButton)`
    border-radius: 4px;
    height: 64px;
    white-space: pre;
    @media (max-width: ${breakpoints.smallMobile}px) {
        padding: 20px 30px;
    }
    margin-bottom: 0px;
`;

const ButtonGroupWrapper = styled(FlexWrapper)`
    margin: 0 auto;
    max-width: 350px;

    a {
        margin-bottom: 16px;
    }
    a:last-child {
        margin-bottom: 0;
    }
`;

const StyledButtons = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    invertedPrimary: PrimaryInvertedButton,
    invertedSecondary: SecondaryInvertedButton,
    sms: SMSButton
};

const Button = ({
    link = '',
    desktopLink = '',
    children,
    variant = 'primary',
    size = 'large',
    disabled,
    onClick,
    text = '',
    tracking
}) => {
    const [href, setHref] = useState(link);
    useEffect(() => {
        let switchedLink = stringSwitch(link, desktopLink);

        if (tracking && window.location.search !== '') {
            const params = new URLSearchParams(
                queryParamsToObj(window.location.search)
            ).toString();

            switchedLink = `${switchedLink}?${params}`;
        }
        setHref(switchedLink);
    }, []);

    const StyledButton = StyledButtons[variant];

    const getStyledButton = useCallback(
        () => (
            <StyledButton
                disabled={disabled}
                size={size}
                onClick={onClick}>
                {children || text}
            </StyledButton>
        ),
        [disabled, size, onClick, children, text]
    );

    return href ? (
        <NavLink href={href}>{getStyledButton()}</NavLink>
    ) : (
        getStyledButton()
    );
};

const ButtonGroup = ({ components }) => (
    <ButtonGroupWrapper direction="column">
        {components.map((subItem) => (
            <Button key={subItem.id} {...subItem}>
                {subItem.text}
            </Button>
        ))}
    </ButtonGroupWrapper>
);
Button.propTypes = {
    children: node,
    text: string,
    link: string,
    desktopLink: string,
    variant: string,
    size: string,
    disabled: bool,
    onClick: func,
    tracking: bool
};

ButtonGroup.propTypes = {
    components: arrayOf(
        shape({
            link: string,
            desktopLink: string,
            variant: string,
            disabled: bool,
            onClick: func
        })
    ).isRequired
};

export default Button;
export { Button, ButtonGroup };
