/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { arrayOf, shape, string, node, object } from 'prop-types';
import favicon from '../../images/favicon.png';

const HeadTags = ({ tags, schemaMarkup, children }) => (
    <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no"
        />
        <meta
            name="facebook-domain-verification"
            content="k1m69tobztm6r1n6cpgikzqp9cpw9s"
        />
        <link rel="icon" href={favicon} />
        {tags
            .map(({ tagName, content, attributes }) => {
                if (tagName === 'title') {
                    return <title key={tagName}>{content}</title>;
                }
                if (tagName === 'meta') {
                    return (
                        <meta
                            key={attributes.property || attributes.name}
                            {...attributes}
                        />
                    );
                }
                return null;
            })
            .filter((tag) => tag)}
        {JSON.stringify(schemaMarkup) !== '{}' && (
            <script data-testid="schema" type="application/ld+json">
                {JSON.stringify(schemaMarkup)}
            </script>
        )}
        {children}
    </Helmet>
);

HeadTags.propTypes = {
    tags: arrayOf(
        shape({ tag: string, content: string, attributes: shape({}) })
    ),
    children: node,
    schemaMarkup: object
};

HeadTags.defaultProps = {
    tags: [],
    children: undefined,
    schemaMarkup: {}
};

export default HeadTags;
