import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import Container from 'TMComponents/Container';
import { breakpoints } from 'TMConstants';
import ContentParser from 'TMComponents/ContentParser';
import Img from 'TMComponents/ImageDynamic';
import { Body, Bold } from 'TMComponents/Typography';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Carousel from 'TMComponents/Carousel';
import imageProps from 'TMPropTypes/imageProps';
import star from './star.svg';

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border-radius: 35px 35px 35px 10px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows[9]};
    flex: 0 0 auto;
    margin: 0 10px;
    max-width: 250px;
    padding: 20px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    @media (min-width: ${breakpoints.tabletDesktop}px) {
        margin-right: 0px;
    }
`;

const Header = styled(FlexWrapper)``;

const Stars = styled(FlexWrapper)`
    margin-left: 30px;
`;

const StarImg = styled.img`
    display: block;
    max-width: 200px;
`;

const ImageSection = styled.div`
    border-radius: 50%;
    height: 56px;
    width: 56px;
`;
const NoPhoto = styled.div`
    background-color: ${(props) => props.theme.colors.greys.regular};

    border-radius: 50%;
`;

const Photo = styled(Img)`
    border-radius: 50%;
    height: 56px;
    width: 56px;
`;

const AuthorName = styled(Body)`
    ${Bold}
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    margin-bottom: 0;
    margin-left: 10px;
`;

const TrustpilotReview = ({ score, image, name, copy }) => {
    return (
        <Wrapper>
            <Header direction="row" align="center">
                <ImageSection>
                    {image && <Photo imageData={image} />}
                    {!image && <NoPhoto role="presentation" />}
                </ImageSection>
                <Stars aria-label={`Rating: ${score} out of 5 stars`}>
                    {[...Array(+score).keys()].map((_, i) => (
                        <StarImg
                            src={star}
                            alt={`${i + 1}-star-icon`}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`star-icon-${i}`}
                        />
                    ))}
                </Stars>
            </Header>
            <ContentParser copy={copy} />
            <AuthorName>{name}</AuthorName>
        </Wrapper>
    );
};

const TrustpilotReviewGroup = ({ components }) => {
    return (
        <Container size="large">
            <Carousel>
                {components.map((subItem) => (
                    <TrustpilotReview key={subItem.id} {...subItem} />
                ))}
            </Carousel>
        </Container>
    );
};

TrustpilotReview.propTypes = {
    score: string.isRequired,
    copy: string.isRequired,
    name: string.isRequired,
    image: shape(imageProps)
};

TrustpilotReviewGroup.propTypes = {
    components: arrayOf(shape(TrustpilotReview.propTypes)).isRequired
};

export default TrustpilotReview;
export { TrustpilotReview, TrustpilotReviewGroup };
