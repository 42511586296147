/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, shape } from 'prop-types';

import cardProps from 'TMPropTypes/cardProps';
import FlexWrapper from 'TMComponents/FlexWrapper';
import HorizontalScroll from 'TMComponents/HorizontalScroll';
import styled from 'styled-components';
import CardV2 from 'TMComponents/Cards/LargeCard';
import InfoCard from 'TMComponents/Cards/InfoCard';
import Card from 'TMComponents/Card';

const Wrapper = styled(FlexWrapper)`
    padding: 16px 0;
    gap: 20px;
`;

const CardContainer = ({ components }) => {
    // Horizontally scroll on mobile all cards which have a background/height
    const horizontalScroll = components[0].height > 0;

    const cards = components.map(({ id, __typename, ...rest }) => {
        switch (__typename) {
            case 'DatoCmsInfoCard':
                return <InfoCard key={id} {...rest} />;
            case 'DatoCmsCard':
                return <Card key={id} {...rest} />;
            default:
                return <CardV2 key={id} {...rest} />;
        }
    });

    if (horizontalScroll) {
        return <HorizontalScroll maxWidth={290}>{cards}</HorizontalScroll>;
    }

    return (
        <Wrapper justify="center" wrap>
            {cards}
        </Wrapper>
    );
};

CardContainer.propTypes = {
    components: arrayOf(shape(cardProps)).isRequired
};

export default CardContainer;
