const React = require('react');
const { ThemeProvider } = require('styled-components');
const { themeObj } = require('TMComponents/GlobalStyle');
const { Layout } = require('TMComponents/Layout');
require('TMComponents/GlobalStyle/fonts/font.css');

// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
    <ThemeProvider theme={themeObj}>{element}</ThemeProvider>
);

// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element }) => <Layout>{element}</Layout>;

exports.onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!('IntersectionObserver' in window)) {
        // eslint-disable-next-line global-require
        require('intersection-observer');
    }
};
