import { useEffect } from 'react';
import { string } from 'prop-types';

const setUpMonetateRetrackerFunc = () => {
    let hasBeenCalledOnce = false;
    return function retrack() {
        if (hasBeenCalledOnce) {
            window.monetateQ = window.monetateQ || [];
            window.monetateQ.push(['trackData']);
        } else hasBeenCalledOnce = true;
    };
};

const MonetateRetracker = ({ href }) => {
    window.triggerMonetateRetrack =
        window.triggerMonetateRetrack || setUpMonetateRetrackerFunc();
    useEffect(window.triggerMonetateRetrack, [href]);
    return null;
};

MonetateRetracker.propTypes = {
    href: string.isRequired
};

export default MonetateRetracker;
