import React from 'react';
import { number } from 'prop-types';
import Lottie from 'TMComponents/Lottie';
import LottieSpinnerJSON from './TMLoaderAnimation.json';

const LottieSpinner = ({ maxWidth }) => (
    <Lottie maxWidth={maxWidth} code={LottieSpinnerJSON} />
);

LottieSpinner.propTypes = {
    maxWidth: number
};

LottieSpinner.defaultProps = {
    maxWidth: undefined
};

export default LottieSpinner;
