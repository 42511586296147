/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { string, node, func } from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import isServerSideRender from 'TMUtils/isServerSideRender';
import queryParamsToObj from 'TMUtils/queryParamsToObj';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import isMobileUser from 'TMUtils/isMobileUser';
import SEOLookup from 'TMUtils/SEOLookup';
import { fireSEOEvent, fireGAEvent } from 'TMUtils/dataLayerPush';

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
    &:visited {
        text-decoration: none;
    }
`;

const Anchor = styled(AnchorLink)`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
    &:visited {
        text-decoration: none;
    }
`;

const A = styled.a`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }

    &:visited {
        text-decoration: none;
    }
`;

const NavLink = ({ href, children, onClick, ...rest }) => {
    const isPrePop = href
        .toLowerCase()
        .includes(
            'https://my.thinkmoney.co.uk/onlineapply/eligibilitycheck'
        ); // needs replacing with new

    const hasTrailingSlash = href.charAt(href.length - 1) === '/';
    const url = hasTrailingSlash || isPrePop ? href : `${href}/`;
    const isAnchor = href.includes('#');
    const isPhoneNumber = href.includes('tel:');
    const isCookieSettings = href === '#cookiesettings';
    const serverSideRender = isServerSideRender();
    const emergencyBankingURL = 'web.thinkmoney.co.uk/login';
    const isLoginButton = href.includes(emergencyBankingURL);

    const isExternal =
        (href.includes('www.') ||
            href.includes('http') ||
            href.includes('.pdf') ||
            isLoginButton) &&
        !isPrePop;

    const isPrivacyPolicy = href.includes('/about/privacy-policy/');
    const appStoreFlag = { ...rest }['data-e2e'] === 'app-store';

    if (isExternal) {
        // All links that go through to some sort of account apply (web or app) need to be tracked by a GA event
        const requiresGA =
            href.includes('apply.thinkmoney') ||
            href.includes('itunes.apple') ||
            href.includes('play.google') ||
            href.includes('onelink.me');

        return (
            <A
                href={href}
                rel="noreferrer noopener"
                target="_blank"
                onClick={() => {
                    const isCredAbilityCTA =
                        window.location.href?.includes(
                            '/credability-credit-report/'
                        );
                    if (requiresGA) {
                        const sourceCode = appStoreFlag
                            ? '8260'
                            : SEOLookup(window.location.href);

                        const utms = queryParamsToObj(
                            window.location.search
                        );
                        const {
                            utmSource,
                            utmMedium,
                            utmCampaign,
                            utmContent
                        } = utms;

                        if (
                            utmContent &&
                            utmMedium &&
                            utmCampaign &&
                            utmSource
                        ) {
                            const payload = {
                                ...utms,
                                platform: href.includes('apply.thinkmoney')
                                    ? 'web'
                                    : 'app'
                            };
                            fireGAEvent(
                                'lpclick',
                                'click',
                                'landing page',
                                utmSource,
                                payload
                            );
                        } else if (sourceCode) {
                            // if CTA is a footer bar app store link, change the sourceCode to the 'Home/footer' value from the lookup table. This can't be done by URL.
                            fireSEOEvent(
                                sourceCode,
                                href.includes('apply.thinkmoney')
                                    ? 'web'
                                    : 'app'
                            );
                        }
                    } else if (isLoginButton) {
                        fireGAEvent(
                            'loginclick',
                            'login button',
                            'website interaction',
                            isMobileUser()
                                ? 'click mobile page'
                                : 'click desktop page'
                        );
                    } else if (isCredAbilityCTA) {
                        fireGAEvent(
                            'lpclick',
                            'sign-up-cta',
                            'credability',
                            'generic-product-page'
                        );
                    }
                    if (onClick) {
                        onClick();
                    }
                }}
                {...rest}>
                {children}
            </A>
        );
    }
    if (isCookieSettings) {
        return (
            <Anchor
                id="cookiesettings"
                to={href}
                onAnchorLinkClick={() => {
                    document.getElementById('cookieSettingsRelay').click();
                }}
                {...rest}>
                {children}
            </Anchor>
        );
    }

    if (isAnchor && !isPrePop) {
        let anchorHref = href;

        if (!serverSideRender) {
            const queryString = window.location.search;
            anchorHref = anchorHref.replace('#', `${queryString}#`);
        }

        return (
            <Anchor
                to={anchorHref}
                {...rest}
                onAnchorLinkClick={() => {
                    const sourceCode = SEOLookup(window.location.href);
                    const validLink =
                        anchorHref.includes('#textme') ||
                        anchorHref.includes('#qrcode');
                    if (sourceCode && validLink) {
                        fireSEOEvent(
                            sourceCode,
                            isMobileUser() ? 'app' : 'web'
                        );
                    }
                }}>
                {children}
            </Anchor>
        );
    }
    // this is due to the onetrust script running in head and needing to rerun to render cookie list
    if (isPrivacyPolicy) {
        return (
            <A href={href} target="_self">
                {children}
            </A>
        );
    }
    if (isPhoneNumber) {
        return <A href={href}>{children}</A>;
    }

    if (isPrePop) {
        const [prePopUrlState, setPrePopUrlState] = useState(href);
        useEffect(() => {
            const queryString = window.location.search;
            let prePopUrl = href + queryString;
            if (href.includes('#')) {
                prePopUrl = href.replace('#', `${queryString}#`);
            }
            setPrePopUrlState(prePopUrl);
        }, []);

        return (
            <StyledLink
                to={prePopUrlState}
                onClick={() => !!onClick && onClick()}
                {...rest}>
                {children}
            </StyledLink>
        );
    }

    return (
        <StyledLink
            to={url}
            onClick={() => !!onClick && onClick()}
            {...rest}>
            {children}
        </StyledLink>
    );
};

NavLink.propTypes = {
    href: string.isRequired,
    children: node.isRequired,
    onClick: func
};

NavLink.defaultProps = {
    onClick: undefined
};

export { NavLink as Unwrapped };
export default NavLink;
