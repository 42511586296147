import React from 'react';
import styled from 'styled-components';
import { BodySmall, FooterTitles } from 'TMComponents/Typography';
import { string } from 'prop-types';

const StyledTitle = styled(BodySmall)`
    ${FooterTitles}
    color: ${({ theme, header }) =>
        header
            ? theme.colorSystem.primary[4]
            : theme.colorSystem.primary[3]};
    display: block;
    list-style-type: none;
    margin-bottom: 24px;
    padding-bottom: 16px;
`;

const Title = ({ header, text }) => (
    <StyledTitle header={header}>{text}</StyledTitle>
);

Title.propTypes = {
    header: string,
    text: string.isRequired
};

Title.defaultProps = {
    header: ''
};

export default Title;
