import React from 'react';
import { node, string, bool, number } from 'prop-types';
import styled from 'styled-components';

const sizes = {
    large: 1280,
    small: 568
};

const Wrapper = styled.div`
    margin-left: auto;
    margin-right: auto;

    ${({ leftAlign }) => leftAlign && 'margin-left: 0px;'}
    ${({ size, maxWidth }) =>
        size && !maxWidth && `max-width:${sizes[size]}px;`}
    ${({ maxWidth }) => maxWidth && `max-width:${maxWidth}px;`}
`;

const Container = ({ leftAlign, size, children, ...rest }) => (
    <Wrapper size={size} leftAlign={leftAlign} {...rest}>
        {children}
    </Wrapper>
);

Container.propTypes = {
    children: node.isRequired,
    leftAlign: bool,
    size: string,
    maxWidth: number
};

Container.defaultProps = {
    leftAlign: false,
    size: undefined,
    maxWidth: 0
};
export default Container;
