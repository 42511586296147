/* eslint-disable global-require, no-case-declarations */
import { string, bool } from 'prop-types';
import React from 'react';
import dictionary from './dictionary';
import SharedOptions from './SharedOptions';

const ComponentMapper = ({
    __typename,
    hideOnMobile,
    hideOnDesktop,
    whiteBackground,
    backgroundVariant,
    ...item
}) => {
    const Component = dictionary[__typename];
    if (!Component) return <p>{__typename}</p>;

    return (
        <SharedOptions
            whiteBackground={whiteBackground}
            backgroundVariant={backgroundVariant}
            hideOnMobile={hideOnMobile}
            hideOnDesktop={hideOnDesktop}
            noSideMargins={
                whiteBackground || backgroundVariant || item.imageCover
            }>
            <Component backgroundVariant={backgroundVariant} {...item} />
        </SharedOptions>
    );
};

ComponentMapper.propTypes = {
    __typename: string.isRequired,
    hideOnMobile: bool,
    hideOnDesktop: bool,
    whiteBackground: bool,
    backgroundVariant: string
};

ComponentMapper.defaultProps = {
    hideOnMobile: false,
    hideOnDesktop: false,
    whiteBackground: false,
    backgroundVariant: ''
};

export default ComponentMapper;
