// fires datalayer.push event with passed data. Used by Google Analytics

/* eslint-disable default-param-last */

const mandatory = () => {
    throw new Error('Missing parameter!');
};

export const fireGAEvent = (
    event = mandatory(),
    eventAction,
    eventCategory,
    eventLabel,
    payload
) => {
    const eventObj = {
        event,
        eventAction,
        eventCategory,
        eventLabel
    };
    window.dataLayer.push({ ...eventObj, ...payload });
};

export const fireSEOEvent = (sourceCode, platform) => {
    if (
        sourceCode === null ||
        sourceCode === undefined ||
        sourceCode.length !== 4
    ) {
        return 'error - sourceCode value incorrect';
    }
    if (platform !== 'app' && platform !== 'web') {
        return 'error - platform value should be "app" or "web"';
    }
    window.dataLayer.push({
        event: 'lpclick',
        eventCategory: 'landing page',
        eventAction: 'click',
        eventLabel: 'SEO',
        utmCampaign: sourceCode,
        platform
    });
    return 'data pushed to dataLayer';
};
