/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { array, string } from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { breakpoints } from 'TMConstants';
import Container from 'TMComponents/Container';
import FlexWrapper from 'TMComponents/FlexWrapper';
import CheckMark from '../Icons/Checkmark';

const HeaderWrapper = styled(FlexWrapper)`
    justify-content: space-between;
    align-items: baseline;
`;

const Label = styled.div`
    ${(props) =>
        props.gradOne &&
        `background-image: linear-gradient(to right, ${props.gradOne}, ${props.gradTwo}); 
    `};
    color: ${({ theme }) => theme.colorSystem.primary['3']};
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px;
    padding: 8px 13px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: ${({ theme }) => theme.colorSystem.UIElements['5']};
    vertical-align: middle;
`;

const TableRound = styled(Table)`
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
`;

const Row = styled.tr`
    border-top: 5px solid white;
`;

const Cell = styled.td`
    padding: 12px;

    &:first-child {
        border-right: 5px solid white;
    }

    &:nth-child(2) {
        text-align: center;

        @media (min-width: ${breakpoints.mobileTablet}px) {
            width: 48px;
            height: 48px;
        }
    }
`;

const HeaderCell = styled(Cell)`
    border-right: 0 !important;
`;

const Line = styled.hr`
    width: 100%;
    margin: 10px 0;
    background-color: black;
`;

const FooterColumns = styled(FlexWrapper)`
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colorSystem.UIElements['5']};
    margin-bottom: 5px;
`;

const BaseCol = styled.div`
    padding: 12px;
`;

const ColumnLeft = styled(BaseCol)`
    text-align: left;
`;

const ColumnRight = styled(BaseCol)`
    font-weight: bold;
    text-align: right;
`;

const AccountFeature = ({
    accountName,
    accountLabel,
    labelGradientOne = '#000000',
    labelGradientTwo = '#000000',
    feature: feat,
    footerContent
}) => {
    const theme = useTheme();
    return (
        <Container size="small">
            <TableRound>
                <thead>
                    <Row>
                        <HeaderCell colSpan="2">
                            <HeaderWrapper>
                                <h3>{accountName}</h3>

                                <Label
                                    gradOne={labelGradientOne}
                                    gradTwo={labelGradientTwo}>
                                    {accountLabel}
                                </Label>
                            </HeaderWrapper>
                        </HeaderCell>
                    </Row>
                </thead>
                <tbody>
                    {feat.map(({ id, feature, check }) => {
                        return (
                            <Row key={id}>
                                <Cell key={id}>
                                    {feature} {check}
                                </Cell>

                                <Cell>
                                    {check ? (
                                        <CheckMark
                                            fill={theme.colorSystem.cta[1]}
                                        />
                                    ) : (
                                        <CheckMark
                                            fill={
                                                theme.colorSystem
                                                    .UIElements[1]
                                            }
                                        />
                                    )}
                                </Cell>
                            </Row>
                        );
                    })}
                </tbody>
            </TableRound>
            <Line />
            <div>
                {footerContent.map(({ id, columnLeft, columnRight }) => (
                    <FooterColumns key={id}>
                        <ColumnLeft>{columnLeft}</ColumnLeft>
                        <ColumnRight>{columnRight}</ColumnRight>
                    </FooterColumns>
                ))}
            </div>
        </Container>
    );
};

AccountFeature.propTypes = {
    accountName: string.isRequired,
    accountLabel: string.isRequired,
    labelGradientOne: string,
    labelGradientTwo: string,
    feature: array.isRequired,
    footerContent: array.isRequired
};

export default AccountFeature;
