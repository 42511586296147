import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useMemo, useCallback } from 'react';

const cookieCategories = {
    performance: 'C0002'
};

const useDatadog = () => {
    const updateConsent = useCallback((event = null) => {
        const categories = event?.detail || window.OnetrustActiveGroups;

        const arePerformanceCookiesAllowed = categories?.includes(
            cookieCategories.performance
        );

        if (arePerformanceCookiesAllowed) {
            datadogRum.setTrackingConsent('granted');
        } else {
            datadogRum.setTrackingConsent('not-granted');
        }
    }, []);

    const config = useMemo(() => {
        return {
            applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID || '',
            clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN || '',
            site: 'datadoghq.eu',
            service: process.env.GATSBY_DATADOG_SERVICE || '',
            env: process.env.GATSBY_DATADOG_ENV || '',
            sessionSampleRate:
                +process.env.GATSBY_DATADOG_SESSION_SAMPLE_RATE || 0,
            sessionReplaySampleRate:
                +process.env.GATSBY_DATADOG_SESSION_REPLAY_RATE || 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            trackingConsent: 'not-granted'
        };
    }, []);

    useEffect(() => {
        datadogRum.init(config);
        datadogRum.startSessionReplayRecording();

        updateConsent();

        window.addEventListener('OneTrustGroupsUpdated', updateConsent);
        return () => {
            window.removeEventListener(
                'OneTrustGroupsUpdated',
                updateConsent
            );
            datadogRum.stopSessionReplayRecording();
        };
    }, [config]);
};

export default useDatadog;
