import React from 'react';
import { string } from 'prop-types';

const Cross = ({ color, ...rest }) => (
    <svg width="17" height="23" viewBox="0 0 17 23" fill="none" {...rest}>
        <path
            d="M14.9743 7.3632C15.5114 6.82609 15.5114 5.95383 14.9743 5.41672C14.4372 4.87961 13.5649 4.87961 13.0278 5.41672L8.50322 9.94562L3.97432 5.42101C3.43721 4.8839 2.56494 4.8839 2.02783 5.42101C1.49072 5.95812 1.49072 6.83039 2.02783 7.3675L6.55674 11.8921L2.03213 16.421C1.49502 16.9581 1.49502 17.8304 2.03213 18.3675C2.56924 18.9046 3.4415 18.9046 3.97861 18.3675L8.50322 13.8386L13.0321 18.3632C13.5692 18.9003 14.4415 18.9003 14.9786 18.3632C15.5157 17.8261 15.5157 16.9538 14.9786 16.4167L10.4497 11.8921L14.9743 7.3632Z"
            fill={color}
        />
    </svg>
);

Cross.propTypes = {
    color: string
};

Cross.defaultProps = {
    color: '#003057'
};

export default Cross;
