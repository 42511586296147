import { func, string, bool, node, arrayOf, shape } from 'prop-types';
import React from 'react';
import NavLink from 'TMComponents/NavLink';
import { Nav, Body } from 'TMComponents/Typography';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { submenuLinkProps } from 'TMPropTypes/headerProps';

const MenuItemContainer = styled(FlexWrapper)`
    border-bottom: 4px solid transparent;
    cursor: pointer;
    text-align: center;
    padding: 36px 20px 32px;
    position: relative;
    transition: border-bottom 0.3s;
    white-space: nowrap;
    ${({ itemIsActive, hasSubmenu, theme }) => {
        const underline = `border-bottom: 4px solid ${theme.colorSystem.primary[4]};`;
        const submenuTail = `&:before{
            content:"";
            position: absolute;
            bottom: -24px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid ${theme.colorSystem.primary[3]};
        }`;

        let css = itemIsActive && underline;
        if (hasSubmenu) {
            css += submenuTail;
        }

        return css;
    }}
`;

export const MenuItemText = styled(Body)`
    ${Nav}
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    margin: 0;
    ${({ itemIsActive, theme }) =>
        itemIsActive &&
        `
        color: ${theme.colorSystem.primary[4]};
    `}
`;

export const MenuLink = styled(NavLink)`
    ${Nav}
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    &:hover {
        color: ${({ theme }) => theme.colorSystem.primary[4]};
        text-decoration: none !important;
    }
`;
const Submenu = styled(FlexWrapper)`
    ${Nav}
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    position: absolute;
    ${({ itemIsActive }) =>
        itemIsActive
            ? `
    &:before{
        content: "";
        height: 17px;
        width: 100%;
        position: absolute;
        top: -17px;
        left:0;
    }
    &:focus-within{
        transition: none;
    }
    left: 0;
    list-style:none;
    top: 94px;
    transition: opacity 0.3s;
    border-radius:8px;
    height: auto;
    width:max-content;
    padding: 16px 16px 0;
    margin-top:17px;
    opacity: 1;
`
            : `
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
`};
`;

const SubmenuItem = styled(FlexWrapper)`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    a:hover,
    a:focus {
        color: ${({ theme }) => theme.colorSystem.primary[4]};
        text-decoration: none;
    }
`;

const SubmenuItemText = styled(Body)`
    margin-bottom: 8px;
    text-transform: initial;
`;

const ConditionalWrapper = ({ url, children }) =>
    url ? <MenuLink href={url}>{children}</MenuLink> : children;

const MenuItem = ({
    title,
    url,
    onHover,
    onHoverStop,
    isActive,
    submenu
}) => {
    return (
        <ConditionalWrapper url={url} as="li">
            <MenuItemContainer
                as="li"
                tabindex="0"
                justify="center"
                onFocus={onHover}
                onBlur={onHoverStop}
                onMouseEnter={onHover}
                onMouseLeave={onHoverStop}
                itemIsActive={isActive}
                hasSubmenu={!url}
                data-testid={`item-${title}`}>
                <MenuItemText itemIsActive={isActive}>
                    {title}
                </MenuItemText>
                {!url && (
                    <Submenu
                        itemIsActive={isActive}
                        data-testid={`menu-${title}`}
                        direction="column"
                        as="ul">
                        {submenu.map(({ id: subId, link, text }) => (
                            <SubmenuItem key={`${subId}`} as="li">
                                <NavLink href={link}>
                                    <SubmenuItemText>
                                        {text}
                                    </SubmenuItemText>
                                </NavLink>
                            </SubmenuItem>
                        ))}
                    </Submenu>
                )}
            </MenuItemContainer>
        </ConditionalWrapper>
    );
};

export default MenuItem;

MenuItem.propTypes = {
    title: string.isRequired,
    onHover: func.isRequired,
    onHoverStop: func.isRequired,
    isActive: bool.isRequired,
    url: string,
    submenu: arrayOf(shape(submenuLinkProps)).isRequired
};
MenuItem.defaultProps = {
    url: ''
};

ConditionalWrapper.propTypes = {
    url: string,
    children: node.isRequired
};
ConditionalWrapper.defaultProps = {
    url: ''
};
