// This function is used to generate SEO tracking codes for dataLayer pushes.
// It takes the url of the page, and returns a Source ID code for use as the utmCampaign parameter.
// If the url is not on the list, undefined is returned instead. False is returned for bad inputs, allowing differentiation between type input and 'not found' errors.

export default (url) => {
    if (typeof url !== 'string') {
        return false;
    }
    const lookupObject = {
        '/current-account/': '8200',
        '/current-account/no-credit-check/': '8201',
        '/current-account/basic/': '8201',
        '/current-account/bad-credit/': '8202',
        '/current-account/bad-credit/bankrupt/': '8202',
        '/current-account/bad-credit/ccj/': '8202',
        '/current-account/bad-credit/iva/': '8202',
        '/current-account/joint/': '8203',
        '/current-account/online-banking/': '8204',
        '/current-account/app/': '8204',
        '/current-account/prepaid-debit-card/': '8205',
        '/current-account/budgeting/': '8206',
        '/current-account/refused/': '8207',
        'Home/footer (app store links, see navlink component)': '8260',
        '/': '8260',
        '/current-account/need-a-bank-account-now/': '8261',
        'NEW PAGE tbc': '8262',
        'NEW PAGE tbc v2': '8263',
        '/loans/': '8264',
        '/current-account/fees/': '8265',
        '/current-account/post-office/': '8266',
        '/current-account/post-office/& others tbc': '8266'
    };
    if (url === '') return 'page location URL is empty string';
    // remove https://thinkmoney.co.uk from url string
    let urlString = `/${url.split('/').slice(3).join('/')}`;
    // remove queries from url string
    [urlString] = urlString.split('?').slice(0, 1);
    // account for missing trailing slashes before '?' character in urls
    urlString = urlString.slice(-1) === '/' ? urlString : `${urlString}/`;

    return lookupObject[urlString];
};
