import React from 'react';
import styled from 'styled-components';
import NavLink from 'TMComponents/NavLink';

const Wrapper = styled.div`
    max-width: 768px;
    .trustpilot-widget {
        height: 300px;
    }
    .tp-widget-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    @media (min-width: 550px) {
        .trustpilot-widget {
            height: auto;
        }
        .tp-widget-wrapper {
            flex-direction: row;
        }
    }
`;

const TrustpilotCarousel = () => (
    <Wrapper>
        <div
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id={process.env.TRUSTPILOT_BUSINESS_ID}
            data-style-height="100%"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages="en">
            <NavLink
                href="https://uk.trustpilot.com/review/thinkmoney.co.uk"
                target="_blank"
                rel="noopener">
                Trustpilot
            </NavLink>
        </div>
    </Wrapper>
);

export default TrustpilotCarousel;
