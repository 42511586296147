import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { number } from 'prop-types';

const Twitter = ({ width = 56 }) => {
    const { colorSystem } = useContext(ThemeContext);

    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 56 56"
            fill="none"
            aria-label="Twitter Icon">
            <g filter="url(#filter0_dd_688_12531)">
                <path
                    d="M29.9996 50C18.9717 50 10 41.0283 10 30.0004C10 18.9724 18.9717 10 29.9996 10C41.0283 10 50 18.9724 50 30.0004C50 41.0283 41.0283 50 29.9996 50"
                    fill={colorSystem.primary[1]}
                />
            </g>
            <path
                d="M20.1258 20.076L28.0299 30.6353L20.0759 39.2205H21.866L28.8298 31.7041L34.4563 39.2205H40.5482L32.1993 28.0673L39.6029 20.076H37.8128L31.3995 26.9985L26.2177 20.076H20.1258ZM22.7583 21.3935H25.557L37.9152 37.9028H35.1166L22.7583 21.3935Z"
                fill={colorSystem.primary[3]}
            />
            <defs>
                <filter
                    id="filter0_dd_688_12531"
                    x="0"
                    y="0"
                    width="56"
                    height="56"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_688_12531"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.647382 0 0 0 0 0.907473 0 0 0 0 0.951483 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_688_12531"
                        result="effect2_dropShadow_688_12531"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_688_12531"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Twitter;

Twitter.propTypes = {
    width: number
};
