import React from 'react';
import { getSrc } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';

const Img = styled.img`
    display: block;
`;

const ImageStatic = ({ imageData, alt, ...rest }) => {
    const src = getSrc(imageData);
    const altText = imageData?.alt || alt;
    return (
        <Img
            alt={altText}
            src={src}
            style={{ display: 'block' }}
            {...rest}
        />
    );
};

export default ImageStatic;

ImageStatic.propTypes = {
    imageData: shape(imageProps).isRequired,
    alt: string
};

ImageStatic.defaultProps = {
    alt: ''
};
