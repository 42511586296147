import React from 'react';
import { bool } from 'prop-types';

const OtCookieList = ({ showCookieList }) =>
    showCookieList && (
        <div
            style={{
                maxWidth: '768px',
                margin: '0 auto',
                fontSize: '18px'
            }}
            id="ot-sdk-cookie-policy"
        />
    );

OtCookieList.propTypes = {
    showCookieList: bool
};

OtCookieList.defaultProps = {
    showCookieList: false
};

export default OtCookieList;
