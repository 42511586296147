import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { number } from 'prop-types';

const Facebook = ({ width = 56 }) => {
    const { colorSystem } = useContext(ThemeContext);

    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Facebook Icon">
            <g filter="url(#filter0_dd)">
                <path
                    d="M30 49.9993C18.9723 49.9993 10 41.0277 10 30C10 18.9723 18.9723 10 30 10C41.0277 10 50 18.9723 50 30C50 41.0277 41.0277 49.9993 30 49.9993"
                    fill={colorSystem.primary[1]}
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.8642 30.0944V41.6857C26.8642 41.8528 26.9991 41.9876 27.1661 41.9876H31.4705C31.6368 41.9876 31.7724 41.8528 31.7724 41.6857V29.9054H34.8933C35.0501 29.9054 35.1806 29.7852 35.1938 29.6291L35.4942 26.0795C35.5096 25.9036 35.3696 25.7519 35.193 25.7519H31.7724V23.2341C31.7724 22.6434 32.2509 22.1649 32.8415 22.1649H35.2458C35.4129 22.1649 35.5477 22.0301 35.5477 21.8637V18.3141C35.5477 18.147 35.4129 18.0122 35.2458 18.0122H31.1818C28.7973 18.0122 26.8642 19.9453 26.8642 22.3298V25.7519H24.7121C24.5457 25.7519 24.4102 25.8875 24.4102 26.0538V29.6035C24.4102 29.7705 24.5457 29.9054 24.7121 29.9054H26.8642V30.0944"
                fill={colorSystem.primary[3]}
            />
            <defs>
                <filter
                    id="filter0_dd"
                    x="0"
                    y="0"
                    width="56"
                    height="55.9993"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.647382 0 0 0 0 0.907473 0 0 0 0 0.951483 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Facebook;

Facebook.propTypes = {
    width: number
};
