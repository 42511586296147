import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { string } from 'prop-types';

const Search = ({ className }) => {
    const { colorSystem } = useContext(ThemeContext);

    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5736 12.0215L12.1394 13.4556L13.8954 15.2116L13.6691 15.4379C13.4947 15.6124 13.4947 15.8952 13.6691 16.0696L20.2405 22.641C20.4149 22.8154 20.6977 22.8154 20.8722 22.641L22.7173 20.7959C22.8917 20.6215 22.8917 20.3387 22.7173 20.1642L16.1459 13.5929C15.9714 13.4184 15.6886 13.4184 15.5142 13.5929L15.3296 13.7775L13.5736 12.0215Z"
                fill={colorSystem.primary[4]}
            />
            <rect
                x="0.799976"
                y="0.800098"
                width="14"
                height="14"
                rx="7"
                stroke={colorSystem.primary[4]}
                strokeWidth="1.4"
            />
        </svg>
    );
};

Search.propTypes = {
    className: string
};

Search.defaultProps = {
    className: ''
};

export default Search;
