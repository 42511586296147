import React from 'react';
import Img from 'TMComponents/ImageDynamic';
import { shape } from 'prop-types';
import IconProps from 'TMPropTypes/imageProps';

const Icon = ({ icon }) => {
    return <Img imageData={icon.icon} />;
};

Icon.propTypes = {
    icon: shape(IconProps).isRequired
};

export default Icon;
