import React, {
    createContext,
    useState,
    useMemo,
    useContext
} from 'react';
import styled from 'styled-components';
import NavLink from 'TMComponents/NavLink';
import {
    Breadcrumbs as BreadcrumbsText,
    Body
} from 'TMComponents/Typography';
import FlexWrapper from 'TMComponents/FlexWrapper';
import HeadTags from 'TMComponents/HeadTags';
import { node } from 'prop-types';
import { breakpoints } from 'TMConstants';

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.colorSystem.primary[4]};
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
    color: ${({ theme }) => theme.colorSystem.primary[3]};
    padding: 16px;
    position: relative;
    z-index: -1;
`;

const BreadcrumbsContent = styled(FlexWrapper)`
    flex-wrap: wrap;
    gap: 4px;
`;
const Crumb = styled(NavLink)`
    display: inline-block;
    &:first-child {
        margin-left: 0;
    }

    &:hover {
        text-decoration: underline;
    }

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0 16px;
    }
`;

const Text = styled(Body)`
    ${BreadcrumbsText}
    margin: 0;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        ${(props) => props.chevron && 'margin-bottom: 1px;'};
    }
`;

const getSchema = (crumbs) => {
    const schemaCrumbs = crumbs.map(({ title, link }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        name: title,
        item: `https://thinkmoney.co.uk${link}`
    }));

    return `{"@context":"https://schema.org","@type":"BreadcrumbList","itemListElement":${JSON.stringify(
        schemaCrumbs
    )}}`;
};

const BreadcrumbsContext = createContext();

const BreadcrumbsContextProvider = ({ children }) => {
    const [crumbs, setCrumbs] = useState({ show: false, crumbList: [] });
    const crumbsState = useMemo(() => ({ crumbs, setCrumbs }), [crumbs]);
    return (
        <BreadcrumbsContext.Provider value={crumbsState}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};

const Breadcrumbs = () => {
    const { crumbs } = useContext(BreadcrumbsContext);

    if (!crumbs?.show) return null;

    const { crumbList } = crumbs;

    return (
        <>
            <HeadTags>
                <script type="application/ld+json">
                    {getSchema(crumbList)}
                </script>
            </HeadTags>
            <Wrapper>
                <BreadcrumbsContent align="center">
                    <Crumb href="/">
                        <Text>Home</Text>
                    </Crumb>
                    {crumbList.map(({ title, link }) => (
                        <React.Fragment key={title}>
                            <Text chevron>{'>'}</Text>
                            <Crumb href={link}>
                                <Text>{title}</Text>
                            </Crumb>
                        </React.Fragment>
                    ))}
                </BreadcrumbsContent>
            </Wrapper>
        </>
    );
};

BreadcrumbsContextProvider.propTypes = {
    children: node.isRequired
};

export default Breadcrumbs;
export {
    Breadcrumbs,
    BreadcrumbsContextProvider,
    BreadcrumbsContext,
    getSchema
};
