import React from 'react';
import string from 'prop-types';

import { themeObj } from '../../GlobalStyle';

const Checkmark = ({ fill, stroke }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="12" fill={fill} />
            <path
                d="M7.8001 13.2L9.73995 14.6549C9.99717 14.8478 10.3607 14.8036 10.5643 14.5548L15.6001 8.39996"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

Checkmark.propTypes = {
    fill: string,
    stroke: string
};

Checkmark.defaultProps = {
    fill: themeObj.colorSystem.primary['4'],
    stroke: themeObj.colorSystem.UIText['3']
};

export default Checkmark;
