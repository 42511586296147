/* eslint-disable react/forbid-prop-types */
import React, { useEffect, createRef } from 'react';
import { number, object } from 'prop-types';
import lottie from 'lottie-web';

const Lottie = ({ code, maxWidth }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: code
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <div
            ref={animationContainer}
            style={{
                maxWidth,
                width: '100%',
                marginBottom: '32px',
                margin: '0 auto'
            }}
            data-testid="animation-container"
            className="animation-container"
        />
    );
};

Lottie.propTypes = {
    code: object.isRequired,
    maxWidth: number
};

Lottie.defaultProps = {
    maxWidth: undefined
};

export default Lottie;
