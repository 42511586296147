/*
  Groups content from the CMS based off their IDs

  Slice data from the CMS is returned in a flattened array which makes it difficult
  to group bits of content so they can be wrapped and positioned correctly by
  a parent element (grid/flex etc)

  This function takes the flat array of CMS data and groups elements into their
  own unique "group" objects so they can be passed into the component mapper and
  wrapped in whatever wrapper is needed for that element

  E.g.

  [
    {} // element 1, doesnt need grouping
    {} // element 2, needs grouping with element 3/4
    {} // element 3, needs grouping as above,
    {} // element 4, needs grouping as above,
    {} // element 5, doesnt need grouping
  ]

  The end result of this grouper is a new flat array, but with grouped content together

  [
    {}                     // element 1
    { components: [] }     // contains elements 2, 3 and 4
    {}                     // element 5
  ]
*/

// TODO: Fill this in with api keys as we flesh out the models in DATO
const defaultKeys = [
    'DatoCmsAppStoreLink',
    'DatoCmsCard',
    'DatoCmsCardV2',
    'DatoCmsInfoCard',
    'DatoCmsTrustpilotReview',
    'DatoCmsButton'
];

// If you dont specify an array of keys, just fall back to the list above
export default (data, keysToGroup = defaultKeys) =>
    data.reduce((arr, next) => {
        // Need to create a unique key for the groups so that any new CMS slices wont collide
        const key = next.__typename;
        const groupKey = `${key}__component_group`;

        // If the current component we're looping isn't in the list of
        // components that need grouping then just return it
        if (!keysToGroup.includes(key)) {
            arr.push(next);
            return arr;
        }

        // Check if we're currently mapping a group,
        // if so, push the comp in to that existing group and return
        const lastItem = arr[arr.length - 1] || {};

        if (lastItem.__typename === groupKey) {
            lastItem.components.push(next);
            for (let i = 0; i < lastItem.components.length; i++) {
                if (lastItem.components[i].hideOnDesktop) {
                    lastItem.hideOnDesktop = true;
                }
                if (lastItem.components[i].hideOnMobile) {
                    lastItem.hideOnMobile = true;
                }
                if (lastItem.components[i].whiteBackground) {
                    lastItem.whiteBackground = true;
                }
            }
            return arr;
        }

        // If get to this point, need to create a new group and push the current component in
        const newGroupObj = {
            id: next.id,
            __typename: groupKey,
            components: [next]
        };

        for (let i = 0; i < newGroupObj.components.length; i++) {
            if (newGroupObj.components[i].hideOnDesktop) {
                newGroupObj.hideOnDesktop = true;
            }
            if (newGroupObj.components[i].hideOnMobile) {
                newGroupObj.hideOnMobile = true;
            }
            if (newGroupObj.components[i].whiteBackground) {
                newGroupObj.whiteBackground = true;
            }
        }
        if (newGroupObj.components[0].backgroundVariant) {
            newGroupObj.backgroundVariant =
                newGroupObj.components[0].backgroundVariant;
        }
        arr.push(newGroupObj);

        return arr;
    }, []);
