/* eslint-disable react/no-this-in-sfc, camelcase */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import { object, shape, string } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';
import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { Button } from 'TMComponents/Button';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Input from 'TMComponents/form/Input';
import { breakpoints } from 'TMConstants';
import { Body } from 'TMComponents/Typography';
import queryParamsToObj from 'TMUtils/queryParamsToObj';
import isMobileUser from 'TMUtils/isMobileUser';
import { fireGAEvent } from 'TMUtils/dataLayerPush';
import ImageWithContent from 'TMComponents/ImageWithContent';
import isStructuredContentEmpty from 'TMUtils/isStructuredContentEmpty';

const InputWrapper = styled(FlexWrapper)`
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 4px;
    box-shadow: inset ${({ theme }) => theme.colorSystem.shadows['6']};
    height: 64px;
    max-width: fit-content;
`;

const StyledInput = styled(Input)`
    border: none !important;
    border-radius: 4px;
    box-shadow: none !important;
    max-width: 177px;
    width: 35vw !important;
`;

const TextMessage = styled(Body)`
    @media (min-width: ${breakpoints.tabletDesktop}px) {
        margin-top: 10px;
        margin-bottom: 0;
    }
    height: 32px;
`;

const queryStringToJSON = () => {
    const pairs = window.location.search.slice(1).split('&');

    const result = {};
    pairs.forEach((pair) => {
        const spitPairs = pair.split('=');
        result[spitPairs[0]] = decodeURIComponent(spitPairs[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};

export const successText =
    'Your message is on the way, please check your phone!';
export const serverErrorText =
    'Oops, there was an error. Please try again.';
export const formatErrorText =
    'Make sure your mobile number starts with 07 and is 11 digits.';
export const tooManyErrorText =
    'You have sent too many texts to that number. Please try again later.';
export const isValidMobileNumberInput = (input) => {
    return /^\d{0,11}$/.test(input);
};
const SMSLink = ({
    buttonText,
    contentStructured,
    contentStructuredMobile,
    image
}) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState(null);

    const sendText = () => {
        setSending(true);
        setMessage(null);

        // Construct payload
        const { protocol, host, pathname, search } = window.location;

        let searchArray = {};

        const hasQueryParams = /\?.+=.*/g.test(window.location.href);
        if (hasQueryParams) {
            searchArray = queryStringToJSON();
        }
        const isMobileNumberValid = /^07(\d){9}$/.test(mobileNumber);
        if (!isMobileNumberValid) {
            setMessage(formatErrorText);
            setSending(false);
            return;
        }
        const data = {
            mobile_number: mobileNumber,
            meta: {
                searchArray,
                protocol,
                host,
                pathname,
                search
            }
        };
        axios
            .post(process.env.GATSBY_SNS_URL, data)
            .then(() => {
                setMessage(successText);
                const utms = queryParamsToObj(window.location.search);
                const { utmSource, utmMedium, utmCampaign, utmContent } =
                    utms;

                const hashedMobileNumber = Base64.stringify(
                    sha256(mobileNumber)
                );

                if (utmContent && utmMedium && utmCampaign && utmSource) {
                    const payload = {
                        ...utms,
                        platform: 'app',
                        hashedNumber: hashedMobileNumber
                    };
                    fireGAEvent(
                        'lpclick',
                        'click',
                        'landing page',
                        utmSource,
                        payload
                    );
                }
            })
            .catch((err) => {
                try {
                    switch (err.response.status) {
                        case 400:
                            setMessage(formatErrorText);
                            break;
                        case 429:
                            setMessage(tooManyErrorText);
                            break;
                        default:
                            setMessage(serverErrorText);
                    }
                } catch (e) {
                    setMessage(serverErrorText);
                }
            })
            .finally(() => setSending(false));
    };

    /* istanbul ignore next */
    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (isValidMobileNumberInput(inputValue)) {
            setMobileNumber(inputValue);
        }
    };

    const isUserMobile = isMobileUser();
    return (
        <ImageWithContent
            image={image}
            contentStructured={
                isUserMobile &&
                !isStructuredContentEmpty(contentStructuredMobile)
                    ? contentStructuredMobile
                    : contentStructured
            }>
            <InputWrapper align="center">
                <StyledInput
                    data-testid="phone_number"
                    id="phone_number"
                    name="phone_number"
                    placeholder="Mobile Number"
                    value={mobileNumber}
                    onChange={handleChange}
                    type="tel"
                    title="Please enter valid UK mobile number"
                    disabled={sending}
                />
                <Button
                    onClick={() => sendText(mobileNumber)}
                    disabled={sending}
                    variant="sms">
                    {buttonText}
                </Button>
            </InputWrapper>
            <TextMessage>{message}</TextMessage>
        </ImageWithContent>
    );
};

SMSLink.propTypes = {
    contentStructured: object.isRequired,
    contentStructuredMobile: object,
    buttonText: string.isRequired,
    image: shape(imageProps).isRequired
};

SMSLink.defaultProps = {
    contentStructuredMobile: undefined
};

export { SMSLink as Unwrapped };
export default SMSLink;
