/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Checkmark from 'TMComponents/Icons/Checkmark';
import Img from 'TMComponents/ImageDynamic';
import { object, array } from 'prop-types';

const MainWrapper = styled(FlexWrapper)`
    gap: 10px;
`;

const SelectWrapper = styled(FlexWrapper)`
    flex-grow: 1;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.5;
    transition: opacity 0.75s;

    &:hover {
        opacity: 1;
    }

    ${(props) => props.active && `opacity: 1;`}
`;

const Selector = styled.button`
    flex-grow: 1;
    padding: 0;
    overflow: hidden;
    border: 3px solid ${({ theme }) => theme.colorSystem.UIElements[4]};
    border-radius: 25px;
    background-color: #ffffff;
    cursor: pointer;

    &.active {
        border: 3px solid ${({ theme }) => theme.colorSystem.primary[4]};
    }
`;

// TODO: This is hack since trying to use active overrides fail
const SelectorRibbon = styled.div`
    position: relative;
    width: 100%;
    padding-top: 8px;
    background-image: linear-gradient(to right, #ffffff, #ffffff);
    transition: background-image 0.75s ease-in-out;

    ${(props) =>
        props.gradOne &&
        `background-image: linear-gradient(to right, ${props.gradOne}, ${props.gradTwo}); 
    `};

    &:before {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.9;
        top: 0;
        left: 0;
        background-color: ${({ theme }) =>
            theme.colorSystem.UIElements[5]};
        transition: opacity 0.75s;
    }

    .active & {
        &:before {
            opacity: 0;
        }
    }
`;

const AccImg = styled(Img)`
    margin: 0 auto;
`;

const AccountSelector = ({ accounts, embla }) => {
    const theme = useTheme();
    const [active, setActive] = useState(0);

    const updateActive = useCallback((emblaApi) => {
        setActive(emblaApi.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (embla) embla.on('select', updateActive);
    }, [embla, updateActive]);

    const changeSlide = useCallback(
        (index) => {
            if (embla) embla.scrollTo(index);
        },
        [embla]
    );

    return (
        <MainWrapper>
            {accounts.map(
                (
                    {
                        id,
                        accountName,
                        accountImage,
                        gradientOne,
                        gradientTwo
                    },
                    i
                ) => {
                    return (
                        <SelectWrapper key={id} active={active === i}>
                            <Selector
                                className={active === i && 'active'}
                                onClick={() => {
                                    changeSlide(i);
                                }}>
                                <AccImg imageData={accountImage} />
                                <h3>{accountName}</h3>
                                <SelectorRibbon
                                    gradOne={gradientOne}
                                    gradTwo={gradientTwo}>
                                    <Checkmark
                                        fill={
                                            active === i
                                                ? theme.colorSystem
                                                      .primary[3]
                                                : theme.colorSystem
                                                      .UIElements[4]
                                        }
                                        stroke={
                                            active === i
                                                ? theme.colorSystem
                                                      .primary[1]
                                                : theme.colorSystem
                                                      .secondary[4]
                                        }
                                    />
                                </SelectorRibbon>
                            </Selector>
                        </SelectWrapper>
                    );
                }
            )}
        </MainWrapper>
    );
};

AccountSelector.propTypes = {
    accounts: array.isRequired,
    embla: object
};

export default AccountSelector;
