/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */

import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import Content from 'TMComponents/Content';
import ImageDynamic from 'TMComponents/ImageDynamic';
import imageProps from 'TMPropTypes/imageProps';
import { array, shape } from 'prop-types';

const baseCardStyles = css`
    width: 100%;
    max-width: 350px;
    border-radius: 32px;
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
`;

const Overflow = styled.div`
    position: relative;
    padding: 8px 20px 0;
    margin-bottom: 8px;
`;
const Overlay = styled.div`
    position: absolute;
    width: 80%;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
`;

const CardBackground = styled.div`
    ${baseCardStyles}
    padding: 12px 0px;
`;

const CardImageBackground = styled(ImageDynamic)`
    &::before,
    &::after {
        ${baseCardStyles};
    }
    ${baseCardStyles};
    padding: 20px 0;
`;

const StyledContent = styled(Content)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const LargeCard = ({ backgroundImage, overlayImage, image, content }) => {
    const theme = useTheme();
    if (backgroundImage) {
        return (
            <CardImageBackground imageData={backgroundImage} isCover>
                <Content
                    content={content}
                    backgroundVariant={theme.colorSystem.primary[3]}
                />
            </CardImageBackground>
        );
    }
    return (
        <CardBackground>
            {image && (
                <Overflow>
                    <ImageDynamic imageData={image} />
                    {overlayImage && (
                        <Overlay>
                            <ImageDynamic imageData={overlayImage} />
                        </Overlay>
                    )}
                </Overflow>
            )}
            <StyledContent
                content={content}
                backgroundVariant={theme.colorSystem.primary[3]}
            />
        </CardBackground>
    );
};

export default LargeCard;

LargeCard.propTypes = {
    content: array.isRequired,
    image: shape(imageProps),
    overlayImage: shape(imageProps),
    backgroundImage: shape(imageProps)
};
