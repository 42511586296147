/* eslint-disable react/forbid-prop-types */

import React, {
    createContext,
    useState,
    useContext,
    useMemo
} from 'react';
import { shape, bool, string, object, node } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';
import colourProps from 'TMPropTypes/colourProps';
import styled from 'styled-components';
import Img from 'TMComponents/ImageStatic';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { Button } from 'TMComponents/Button';
import ContentParserStructured from 'TMComponents/ContentParserStructured';
import { SmallText, MediumText } from 'TMComponents/Typography';
import { themeObj } from 'TMComponents/GlobalStyle';

const BannerWrapper = styled(FlexWrapper)`
    background: ${(props) => props.primaryColour};
    padding: 1vh 0;
    width: 100%;
    z-index: 5;
`;

const InnerWrapper = styled(FlexWrapper)`
    padding: 0 0.4em;
    p,
    strong,
    a {
        color: ${({ colour }) => colour};
        ${MediumText}
        margin-bottom: 0 !important;
    }
    a:hover {
        color: ${({ colour }) => colour};
    }
    a:focus {
        color: ${({ colour }) => colour};
    }
`;
const ImageWrapper = styled.div`
    min-width: 50px;
`;

const Content = styled.div`
    margin: 0 0.5em;
    margin-top: -16px;
`;
const BannerButton = styled(Button)`
    ${SmallText}
    margin: 0 1em;
    padding: 1em;
    white-space: nowrap;
`;

const BannerContext = createContext();

const BannerContextProvider = ({ children }) => {
    const [showBanner, setShowBanner] = useState(false);
    const bannerState = useMemo(
        () => ({ showBanner, setShowBanner }),
        [showBanner]
    );
    return (
        <BannerContext.Provider value={bannerState}>
            {children}
        </BannerContext.Provider>
    );
};

const Banner = ({
    backgroundPrimaryColour,
    image,
    structuredContent,
    textColour,
    showButton,
    buttonText,
    buttonLink,
    desktopButtonLink
}) => {
    const { showBanner } = useContext(BannerContext);

    if (!showBanner) return null;

    return (
        <BannerWrapper
            data-testid="SiteBanner"
            align="center"
            justify="center"
            primaryColour={backgroundPrimaryColour.hex}>
            <InnerWrapper
                direction="row"
                align="center"
                justify="center"
                colour={textColour.hex}>
                {image && (
                    <ImageWrapper>
                        <Img
                            data-testid="BannerImage"
                            imageData={image}
                            loading="eager"
                        />
                    </ImageWrapper>
                )}
                <Content>
                    <ContentParserStructured
                        structuredCopy={structuredContent}
                    />
                </Content>
                {showButton && (
                    <BannerButton
                        link={buttonLink}
                        desktopLink={desktopButtonLink}>
                        {buttonText}
                    </BannerButton>
                )}
            </InnerWrapper>
        </BannerWrapper>
    );
};

Banner.propTypes = {
    structuredContent: object.isRequired,
    backgroundPrimaryColour: shape(colourProps),
    buttonLink: string,
    buttonText: string,
    desktopButtonLink: string,
    image: shape(imageProps),
    showButton: bool,
    textColour: shape(colourProps)
};
Banner.defaultProps = {
    backgroundPrimaryColour: { hex: themeObj.colorSystem.primary[4] },
    buttonLink: '',
    buttonText: '',
    desktopButtonLink: '',
    image: undefined,
    showButton: false,
    textColour: { hex: themeObj.colorSystem.primary[3] }
};

BannerContextProvider.propTypes = {
    children: node.isRequired
};

export default Banner;
export { Banner, BannerContextProvider, BannerContext };
