import React, {
    createContext,
    useState,
    useMemo,
    Suspense,
    lazy
} from 'react';
import { node } from 'prop-types';
import HeadTags from 'TMComponents/HeadTags';
import GlobalStyle from 'TMComponents/GlobalStyle';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import isServerSideRender from 'TMUtils/isServerSideRender';
import MonetateRetracker from 'TMComponents/Monetate';
import { BannerContextProvider } from 'TMComponents/Banner';
import { BreadcrumbsContextProvider } from 'TMComponents/Breadcrumbs';
import Header from './Header';
import useDatadog from '../../hooks/useDatadog';

const Footer = lazy(() => import('./Footer'));

const LayoutContext = createContext({
    isNavHidden: false,
    setIsNavHidden: null
});

const LayoutContextProvider = LayoutContext.Provider;

const Layout = ({ children }) => {
    const serverSideRender = isServerSideRender();
    useDatadog();
    const {
        favicon: { tags }
    } = useStaticQuery(graphql`
        {
            favicon: datoCmsFaviconMetaTags {
                tags
            }
        }
    `);

    const [isNavHidden, setIsNavHidden] = useState(false);

    const navState = useMemo(
        () => ({ isNavHidden, setIsNavHidden }),
        [isNavHidden]
    );

    return (
        <>
            <noscript>
                <iframe
                    src="//www.googletagmanager.com/ns.html?id=GTM-PBZQ2N"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                    title="tag-manager"
                />
            </noscript>
            <GlobalStyle />
            <HeadTags tags={tags}>
                {/* <!-- AppsFlyer script --> */}
                <script
                    type="text/javascript"
                    src={withPrefix('onelink-smart-script-latest.js')}
                    async
                />
                {/* <!-- End AppsFlyer script --> */}
                {/* <!-- TrustBox script --> */}
                <script
                    type="text/javascript"
                    src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                    async
                />
                {/* <!-- End TrustBox script --> */}
            </HeadTags>
            <LayoutContextProvider value={navState}>
                <BannerContextProvider>
                    <BreadcrumbsContextProvider>
                        <Header hideNav={isNavHidden} />
                        {children}
                        {!serverSideRender && (
                            <>
                                <MonetateRetracker
                                    href={window.location.href}
                                />
                                <Suspense fallback={<div />}>
                                    <Footer hideNav={isNavHidden} />
                                </Suspense>
                            </>
                        )}
                    </BreadcrumbsContextProvider>
                </BannerContextProvider>
            </LayoutContextProvider>
        </>
    );
};

Layout.propTypes = {
    children: node.isRequired
};

export default Layout;
export { Layout, LayoutContextProvider, LayoutContext };
