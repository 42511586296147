/* istanbul ignore file */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { displayQRCode } from '..';

const QRWrapper = styled.div`
    display: flex;
`;

const removeChildren = (divRef) => {
    if (divRef.current) {
        while (divRef.current.firstChild) {
            divRef.current.removeChild(divRef.current.firstChild);
        }
    }
};

const QR_WRAPPER_ID = 'qrcode-wrapper';

const QRCode = () => {
    const qrWrapper = useRef(null);

    useEffect(() => {
        const observer = new MutationObserver((_, obs) => {
            /* istanbul ignore next */
            if (window.AF_SMART_SCRIPT) {
                removeChildren(qrWrapper);
                displayQRCode(QR_WRAPPER_ID, uuidv4());
                obs.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true
        });

        return () => observer.disconnect();
    }, []);

    return (
        <QRWrapper
            ref={qrWrapper}
            id={QR_WRAPPER_ID}
            data-testid={QR_WRAPPER_ID}
        />
    );
};

export default QRCode;
