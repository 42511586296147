/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import string from 'prop-types';

const Remove = ({ fill }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/Remove" clipPath="url(#clip0)">
                <rect
                    id="Rectangle 8"
                    x="1.43836"
                    y="20"
                    width="26.25"
                    height="26.25"
                    rx="13.125"
                    transform="rotate(-45 1.43836 20)"
                    stroke="white"
                    strokeWidth="1.75"
                />
                <path
                    id="+"
                    d="M23.8642 25.4052L20.3672 21.9082L16.8702 25.4052C16.6475 25.6279 16.2466 25.6279 16.0238 25.4052L15.4002 24.7815C15.1774 24.5588 15.1774 24.1578 15.4002 23.9351L18.8972 20.4381L15.3779 16.9188C15.1552 16.6961 15.1774 16.3174 15.4002 16.0947L16.0238 15.471C16.2466 15.2483 16.6252 15.226 16.848 15.4488L20.3672 18.968L23.8865 15.4488C24.1092 15.226 24.4879 15.2483 24.7106 15.471L25.3343 16.0947C25.557 16.3174 25.5793 16.6961 25.3566 16.9188L21.8373 20.4381L25.3343 23.9351C25.557 24.1578 25.5348 24.5365 25.312 24.7592L24.6884 25.3829C24.4656 25.6056 24.087 25.6279 23.8642 25.4052Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="28"
                        height="28"
                        fill="white"
                        transform="translate(0.200928 20) rotate(-45)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Remove.propTypes = {
    fill: string
};

Remove.defaultProps = {
    fill: 'none'
};

export default Remove;
