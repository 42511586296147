import { func, string } from 'prop-types';
import React from 'react';
import NavLink from 'TMComponents/NavLink';
import { Body, Nav } from 'TMComponents/Typography';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import ChevronRight from 'TMComponents/Icons/ChevronRight';

export const MenuItemContainer = styled(FlexWrapper)`
    display: flex;
    margin: 0 20px;
    outline: none;
    padding: 20px 0;
    ${Nav}
`;

export const MenuItemText = styled(Body)`
    margin: 0;
`;

const MenuItem = ({ title, url, onClick }) => {
    return (
        <MenuItemContainer
            justify="space-between"
            align="center"
            onClick={!url ? onClick : null}
            data-testid={`row-${title}`}>
            <MenuItemText>
                {url ? (
                    <NavLink href={url} onClick={onClick}>
                        {title}
                    </NavLink>
                ) : (
                    title
                )}
            </MenuItemText>
            {!url && <ChevronRight />}
        </MenuItemContainer>
    );
};

export default MenuItem;

MenuItem.propTypes = {
    title: string.isRequired,
    onClick: func.isRequired,
    url: string
};
MenuItem.defaultProps = {
    url: ''
};
