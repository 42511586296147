import { createGlobalStyle } from 'styled-components';
import { Strong, Mark } from '../Typography';

export const themeObj = {
    colorSystem: {
        cta: {
            1: '#1E9F60',
            2: '#15653E',
            3: '#0C66D4'
        },
        primary: {
            1: '#003057',
            2: '#494C6F',
            3: '#FFFFFF',
            4: '#0C66D4',
            5: '#1E9F60'
        },
        secondary: {
            1: '#59B0EF',
            2: '#F5FBFF',
            3: '#FFB494',
            4: '#FFEAE2',
            5: '#F1F9F5'
        },
        UIText: {
            1: '#003057',
            2: '#494C6F',
            3: '#FFFFFF',
            4: '#676B9B',
            5: '#AF3131'
        },
        UIElements: {
            1: '#908861',
            2: '#404040',
            3: '#6C6F72',
            4: '#E3E7F0',
            5: '#F9FAFD',
            6: '#F4F6FB',
            7: '#E5F4FF'
        },
        validation: {
            1: '#1E9F60',
            2: '#F1F9F5',
            3: '#AF3131',
            4: '#FFE5E5'
        },
        gradients: {
            1: ['#003057', '#013F73'],
            2: ['#E5F4FF', '#F1F9F5'],
            3: ['#F1F9F5', '#E5F4FF'],
            4: ['#003057', '#5D6088'],
            5: ['#5D6088', '#003057']
        },
        accountGradients: {
            1: ['#0060A9', '#1188E9', '#399EEF']
        },
        iconography: {
            1: ['#CBE9FF', '#145A8C'],
            2: ['#E5FFF2', '#1E764A'],
            3: ['#FFF8F5', '#D26F60'],
            4: ['#E3E5FC', '#5D6088']
        },
        shadows: {
            1: '0 1px 5px 0 #00000080',
            2: '0 16px 16px #f1f1f1',
            3: '0 -1px 5px 0 #00000033, -2px -2px 10px 0 #e5f7ff1a',
            4: '2px 2px 20px 0 #2222221a, -10px -10px 20px 0 #ffffff',
            5: '20px 20px 60px 0 #e2e2e270, -20px -20px 60px 0 #ffffff',
            6: '1px 1px 1px 0 #0000001a',
            7: '1px -1px 4px 0 #22222233',
            8: '0 1px 5px #0000001a',
            9: '0px 4px 4px 0px #0000000A'
        },
        validations: {
            1: '#1E9F60',
            2: '#AF3131',
            3: '#FFE5E5'
        }
    },
    // deprecated
    colors: {
        secondary: '#EFF2F9',
        black: '#222222',
        greys: {
            lightest: '#F6F7FA',
            light: '#F5F5F5',
            regular: '#C5C5C5',
            dark: '#595959',
            darkest: '#474747'
        }
    }
};

export default createGlobalStyle`
    html, body {
        margin: 0; 
        padding: 0;
        height: 100%;
        width: 100%;
        background:${themeObj.colorSystem.UIElements[5]} !important;
        max-width: 100%;
        color: ${themeObj.colorSystem.UIText[1]};
        ${Strong}
        ${Mark}
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
        h1,h2,h3,h4,h5,h6,p {
            background: none !important;
            -webkit-text-fill-color: inherit !important;
        }
    }
`;
