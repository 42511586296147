/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { object, string, bool, shape, node } from 'prop-types';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import ContentParserStructured from 'TMComponents/ContentParserStructured';
import { Button } from 'TMComponents/Button';
import DynamicImg from 'TMComponents/ImageDynamic';
import { breakpoints } from 'TMConstants';
import imageProps from 'TMPropTypes/imageProps';
import stringSwitch from 'TMUtils/stringSwitch';
import stringToDast from 'TMUtils/stringToDast';

const Sections = styled(FlexWrapper)`     
    justify-content:center;
    flex-direction: column;
    max-width:1440px;
    ${({ imageCover }) =>
        imageCover &&
        `
        margin: 0 auto !important;  
    `}
    ${(props) =>
        props.mobileImagePosition === 'below' &&
        `
        flex-direction: column-reverse;  
    `}
    @media (min-width: ${breakpoints.mobileTablet}px) {
        display:flex;
        align-items: center;
        ${({ imagePosition }) => {
            switch (imagePosition) {
                case 'above':
                    return 'flex-direction: column;';
                case 'right':
                    return 'flex-direction: row-reverse;';
                case 'below':
                    return 'flex-direction: column-reverse;';
                default:
                    return 'flex-direction: row;';
            }
        }}
    @media (max-width: ${breakpoints.laptop}px) {
        justify-content: space-between;

    }
`;

const Section = styled(FlexWrapper)`
    flex-direction: column;
    justify-content: center;
    padding: 16px 0;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: 50%;
        max-width: 50%;
    }
`;

const ContentSection = styled(Section)`
    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: max-content;
        max-width: 720px;
        margin: 0 64px;
    }
`;

const ImageSection = styled(Section)`
    ${({ hideMobileImage }) => hideMobileImage && 'display: none;'}
    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: initial;
    }
`;

const CoverImageSection = styled(ImageSection)`
    margin: -16px;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        min-width: 480px;
        ${({ imagePosition }) => `
            margin: -16px 16px;
            margin-${imagePosition}: -24px;
        `}
    }
`;

const ImageWithContent = ({
    image,
    contentStructured,
    imagePosition,
    mobileImagePosition,
    linkText,
    linkUrl,
    linkDesktopUrl,
    isButtonLink,
    listCheckMarks,
    subText,
    hideMobileImage,
    imageCover,
    backgroundVariant,
    children
}) => {
    const structuredTextLink =
        linkText &&
        !isButtonLink &&
        stringToDast(
            'link',
            linkText,
            stringSwitch(linkUrl, linkDesktopUrl)
        );
    const structuredSubText =
        subText && stringToDast('paragraph', subText);
    return (
        <Sections
            justify="center"
            imagePosition={imagePosition}
            mobileImagePosition={mobileImagePosition}
            imageCover={imageCover}>
            {imageCover ? (
                <CoverImageSection
                    hideMobileImage={hideMobileImage}
                    imagePosition={imagePosition}>
                    <DynamicImg isCover imageData={image} />
                </CoverImageSection>
            ) : (
                <ImageSection hideMobileImage={hideMobileImage}>
                    <DynamicImg imageData={image} />
                </ImageSection>
            )}
            <ContentSection>
                <ContentParserStructured
                    structuredCopy={contentStructured}
                    listCheckMarks={listCheckMarks}
                    leftAlign
                    backgroundVariant={backgroundVariant}
                />
                {linkText && isButtonLink ? (
                    <Button link={linkUrl} desktopLink={linkDesktopUrl}>
                        {linkText}
                    </Button>
                ) : (
                    linkText &&
                    !isButtonLink && (
                        <ContentParserStructured
                            leftAlign
                            structuredCopy={structuredTextLink}
                        />
                    )
                )}
                {subText && (
                    <ContentParserStructured
                        bodyCopyVariant="small"
                        leftAlign
                        structuredCopy={structuredSubText}
                        backgroundVariant={backgroundVariant}
                    />
                )}
                {children}
            </ContentSection>
        </Sections>
    );
};

ImageWithContent.propTypes = {
    image: shape(imageProps).isRequired,
    children: node,
    contentStructured: object.isRequired,
    imagePosition: string,
    mobileImagePosition: string,
    hideMobileImage: bool,
    linkText: string,
    linkUrl: string,
    linkDesktopUrl: string,
    isButtonLink: bool,
    listCheckMarks: bool,
    imageCover: bool,
    subText: string,
    backgroundVariant: string
};

ImageWithContent.defaultProps = {
    children: undefined,
    linkText: '',
    linkUrl: '',
    linkDesktopUrl: '',
    isButtonLink: false,
    listCheckMarks: false,
    hideMobileImage: false,
    imageCover: false,
    subText: undefined,
    imagePosition: 'right',
    mobileImagePosition: 'below'
};

export default ImageWithContent;
