import React from 'react';
import { string } from 'prop-types';

const ChevronRight = ({ color, ...rest }) => (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" {...rest}>
        <path
            d="M1.50003 14.2778L8.50003 7.7778L1.50003 1.2778"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

ChevronRight.propTypes = {
    color: string
};

ChevronRight.defaultProps = {
    color: '#003057'
};

export default ChevronRight;
