/* eslint-disable react/forbid-prop-types */
import React from 'react';
import contentGrouper from 'TMUtils/contentGrouper';
import ComponentMapper from 'TMComponents/ComponentMapper';
import { array, bool, node, string } from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 0 16px;
    ${(props) =>
        props.noSidePadding &&
        `
        padding: 0;
    `}
    & > div > *:only-child {
        margin: ${({ isPageContent }) =>
            isPageContent ? '16px auto' : '8px auto'};
        ${({ leftAlign }) => leftAlign && 'margin-left: 0px;'}
    }
`;

const Content = ({
    content,
    leftAlign,
    noSidePadding,
    isPageContent,
    backgroundVariant,
    children,
    ...rest
}) => (
    <Wrapper
        noSidePadding={noSidePadding}
        isPageContent={isPageContent}
        leftAlign={leftAlign}
        {...rest}>
        {contentGrouper(content).map((item) => (
            <ComponentMapper
                key={item.id}
                {...item}
                {...(backgroundVariant && { backgroundVariant })}
            />
        ))}
        {children}
    </Wrapper>
);

Content.propTypes = {
    children: node,
    content: array.isRequired,
    leftAlign: bool,
    noSidePadding: bool,
    isPageContent: bool,
    backgroundVariant: string
};
Content.defaultProps = {
    children: undefined,
    leftAlign: false,
    noSidePadding: false,
    isPageContent: false
};

export default Content;
