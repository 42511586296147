/* eslint-disable prefer-destructuring */
export default (type, value, url) => {
    if (!type) return {};
    if (!value) return {};

    const structuredText = {
        type: 'paragraph',
        children: [
            {
                type: 'span'
            }
        ]
    };

    if (type === 'paragraph') {
        structuredText.children[0].value = value;
    }
    if (type === 'link') {
        structuredText.children[0].type = type;
        structuredText.children[0].url = url;
        structuredText.children[0].children = [
            {
                type: 'span',
                value
            }
        ];
    }

    return structuredText;
};
