import React, { useMemo } from 'react';
import styled from 'styled-components';
import { number, string, bool, shape } from 'prop-types';
import { breakpoints } from 'TMConstants';
import { H3, Body, Bold } from '../Typography';
import FlexWrapper from '../FlexWrapper';
import formatCurrency from '../../utils/formatCurrency';

// Styles --------------------------------------------------------------------
const Title = styled(H3)`
    margin-bottom: 8px;
    @media (max-width: ${breakpoints.mobileTablet}px) {
        font-size: 20px;
    }
`;

const BodyNarrow = styled(Body)`
    margin: 12px 0;
    line-height: 8px;
    @media (max-width: ${breakpoints.mobileTablet}px) {
        font-size: 12px;
    }
`;
const BodyNarrowBold = styled(BodyNarrow)`
    ${Bold}
    margin: 12px 0;
    @media (max-width: ${breakpoints.mobileTablet}px) {
        font-size: 12px;
    }
`;
const VerticalSeparator = styled.div`
    min-height: 100%;
    margin: 0 10px;
    border: 1px solid ${({ theme }) => theme.colorSystem.primary['1']};

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0 30px;
    }
`;

const MainContainer = styled.div`
    padding: 20px 40px;
    display: inline-block;

    @media (max-width: ${breakpoints.mobileTablet}px) {
        padding: 0;
        width: 100%;
        margin-bottom: 16px;
    }
`;

// Main component ------------------------------------------------------------

const RepExample = ({
    displayTitle,
    representativeApr,
    purchaseRateApr,
    assumedCreditLimit: ACL,
    isRepresentativeAprVariable,
    isPurchaseRateAprVariable
}) => {
    const title = displayTitle ? (
        <Title>Representative example</Title>
    ) : null;
    const assumedCreditLimitStr = useMemo(
        () => formatCurrency(ACL, { hidePence: true }),
        [ACL]
    );

    return (
        <MainContainer>
            {title}
            <FlexWrapper direction="row" justify="space-between">
                <RateCell
                    title="Representative"
                    rate={representativeApr}
                    isVariable={isRepresentativeAprVariable}
                />
                <VerticalSeparator />
                <RateCell
                    title="Purchase rate"
                    rate={purchaseRateApr}
                    isVariable={isPurchaseRateAprVariable}
                />
                <VerticalSeparator />
                <Cell
                    line1={{ text: 'Assumed', isBold: false }}
                    line2={{ text: 'Credit Limit', isBold: false }}
                    line3={{ text: assumedCreditLimitStr, isBold: true }}
                    testId="credit limit cell"
                />
            </FlexWrapper>
        </MainContainer>
    );
};

export default RepExample;

// Helper components ---------------------------------------------------------

const RateCell = ({ title, rate, isVariable }) => {
    const rateStr = `${rate}% APR`;
    const rateTypeStr = `(${isVariable ? 'variable' : 'fixed'})`;

    return (
        <Cell
            line1={{ text: title, isBold: false }}
            line2={{ text: rateStr, isBold: true }}
            line3={{ text: rateTypeStr, isBold: true }}
            testId={`${title.toLowerCase()} cell`}
        />
    );
};

const Cell = ({ line1, line2, line3, testId }) => {
    return (
        <div data-testid={testId}>
            <Line {...line1} />
            <Line {...line2} />
            <Line {...line3} />
        </div>
    );
};

const Line = ({ text, isBold }) => {
    const TextComp = isBold ? BodyNarrowBold : BodyNarrow;
    return (
        <div>
            <TextComp>{text}</TextComp>
        </div>
    );
};

// Props ---------------------------------------------------------------------

const lineProps = {
    text: string.isRequired,
    isBold: bool.isRequired
};
Line.propTypes = { ...lineProps };

Cell.propTypes = {
    line1: shape(lineProps).isRequired,
    line2: shape(lineProps).isRequired,
    line3: shape(lineProps).isRequired,
    testId: string.isRequired
};

RateCell.propTypes = {
    title: string.isRequired,
    rate: number.isRequired,
    isVariable: bool.isRequired
};

RepExample.propTypes = {
    displayTitle: bool,
    representativeApr: number.isRequired,
    purchaseRateApr: number.isRequired,
    assumedCreditLimit: number.isRequired,
    isRepresentativeAprVariable: bool,
    isPurchaseRateAprVariable: bool
};

RepExample.defaultProps = {
    displayTitle: true,
    isRepresentativeAprVariable: true,
    isPurchaseRateAprVariable: true
};
