import React from 'react';
import { BodySmall } from 'TMComponents/Typography';
import NavLink from 'TMComponents/NavLink';
import { string } from 'prop-types';

const ColumnLink = ({ link, text }) => (
    <NavLink href={link}>
        <BodySmall>{text}</BodySmall>
    </NavLink>
);

ColumnLink.propTypes = {
    link: string.isRequired,
    text: string.isRequired
};

export default ColumnLink;
