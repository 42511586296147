/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { shape, string, bool, object } from 'prop-types';
import styled from 'styled-components';
import imageProps from 'TMPropTypes/imageProps';
import ContentParser from 'TMComponents/ContentParser';
import ContentParserStructured from 'TMComponents/ContentParserStructured';
import Img from 'TMComponents/ImageDynamic';
import { H2 } from 'TMComponents/Typography';
import { Button } from 'TMComponents/Button';
import stringToDast from 'TMUtils/stringToDast';
import isStructuredContentEmpty from 'TMUtils/isStructuredContentEmpty';
import Container from 'TMComponents/Container';

const Wrapper = styled(Container)`
    padding: 16px 0;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 16px;
`;

const IconWithContent = ({
    icon,
    title,
    copy,
    linkText,
    linkUrl,
    linkDesktopUrl,
    contentStructured,
    listCheckMarks,
    subText
}) => {
    const structuredSubText =
        subText && stringToDast('paragraph', subText);

    return (
        <Wrapper size="small">
            <Img imageData={icon.icon} />
            {isStructuredContentEmpty(contentStructured) ? (
                <>
                    <H2 white>{title}</H2>
                    <ContentParser copy={copy} />
                </>
            ) : (
                <ContentParserStructured
                    structuredCopy={contentStructured}
                    listCheckMarks={listCheckMarks}
                />
            )}
            {linkText && (
                <ButtonWrapper>
                    <Button link={linkUrl} desktopLink={linkDesktopUrl}>
                        {linkText}
                    </Button>
                </ButtonWrapper>
            )}
            {subText && (
                <ContentParserStructured
                    bodyCopyVariant="small"
                    leftAlign
                    structuredCopy={structuredSubText}
                />
            )}
        </Wrapper>
    );
};

IconWithContent.propTypes = {
    icon: shape({
        icon: shape(imageProps)
    }).isRequired,
    title: string.isRequired,
    copy: string.isRequired,
    contentStructured: object,
    listCheckMarks: bool,
    linkText: string,
    linkUrl: string,
    linkDesktopUrl: string,
    subText: string
};

IconWithContent.defaultProps = {
    contentStructured: undefined,
    listCheckMarks: false,
    linkText: undefined,
    linkUrl: undefined,
    linkDesktopUrl: undefined,
    subText: ''
};

export default IconWithContent;
