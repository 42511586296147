import React from 'react';
import { string, shape } from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from 'TMConstants';

const Wrapper = styled.div`
    aspect-ratio: 1.6;
    height: 100%;
    max-height: 288px;
    padding: 16px 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 8px;
        max-height: 675px;
    }
`;

export const youtubeGetID = (url) => {
    let ID = '';
    const splitUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const suspectedID = splitUrl[2];
    if (suspectedID) {
        ID = suspectedID.split(/[^0-9a-z_-]/i);
        // eslint-disable-next-line prefer-destructuring
        ID = ID[0];
    }
    return ID;
};

const Video = ({ source: { url, title, className } }) => {
    let processedUrl = url;

    const isYoutubeURL = url.includes('www.youtube');

    if (isYoutubeURL) {
        processedUrl = `https://www.youtube.com/embed/${youtubeGetID(
            url
        )}`;
    }

    return (
        <Wrapper>
            <iframe
                className={className}
                src={processedUrl}
                frameBorder="0"
                title={title}
                allowFullScreen
                width="100%"
                height="100%"
            />
        </Wrapper>
    );
};

Video.propTypes = {
    className: string,
    source: shape({
        url: string.isRequired,
        title: string.isRequired
    }).isRequired
};

Video.defaultProps = {
    className: ''
};

export default Video;
