/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */

import { array, oneOf, shape } from 'prop-types';
import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import Content from 'TMComponents/Content';
import Icon from 'TMComponents/Icon';
import imageProps from 'TMPropTypes/imageProps';

const baseCardStyles = css`
    width: 100%;
    max-width: 350px;
    border-radius: 32px;
    padding: 0 20px;
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
`;

const CardBackground = styled.div`
    ${baseCardStyles}
    border-radius: 16px;
    display: flex;

    ${({ position }) =>
        position === 'right' &&
        `
        justify-content: space-between;
        flex-direction: row-reverse;  
    `}
`;
const IconWrapper = styled.div`
    padding: 16px 0;
    ${({ position }) =>
        position === 'right' &&
        `
        padding-left: 16px; 
    `}
    ${({ position }) =>
        position === 'left' &&
        `
        padding-right: 16px; 
    `}
`;

const InfoCard = ({ content, sideIcon = null, iconPosition = 'left' }) => {
    const theme = useTheme();

    return (
        <CardBackground position={iconPosition}>
            {sideIcon && (
                <IconWrapper position={iconPosition}>
                    <Icon icon={sideIcon} />
                </IconWrapper>
            )}
            <Content
                content={content}
                noSidePadding
                backgroundVariant={theme.colorSystem.primary[3]}
            />
        </CardBackground>
    );
};

export default InfoCard;

InfoCard.propTypes = {
    content: array.isRequired,
    sideIcon: shape(imageProps),
    iconPosition: oneOf(['left', 'right'])
};
