/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useState } from 'react';
import styled from 'styled-components';
import { string, func, array } from 'prop-types';
import Icon from 'TMComponents/Icons/ChevronRight';
import { breakpoints } from 'TMConstants';
import { FormFieldLabel } from '../../Typography';

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    box-shadow: inset ${({ theme }) => theme.colorSystem.shadows['6']};
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    ${FormFieldLabel}
    height: 64px;
    position: relative;
    text-align: left;
    @media (max-width: ${breakpoints.mobile}px) {
        width: calc(100% - 40px);
    }
    width: 343px;
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${(props) => props.theme.colors.greys.light};
    }
`;
const Header = styled.div``;
const HeaderTitle = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    position: relative;
`;
const ListWrapper = styled.ul`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    left: -1px;
    list-style: none;
    margin-top: 0px;
    max-height: 445px;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 65px;
    width: 100%;
`;
const ListItem = styled.li`
    padding: 10px 0 10px 20px;
    position: relative;
    p {
        margin: 0;
    }
    :hover {
        background-color: ${(props) => props.theme.colors.greys.light};
    }
`;
const Arrow = styled(Icon)`
    position: relative;
    transform: rotate(${(props) => (props.isOpen ? '-90deg' : '90deg')});
`;

const Dropdown = ({ value, onItemSelect, optionList }) => {
    const [isListOpen, toggleMenu] = useState(false);
    return (
        <Wrapper data-testid="dropdown-comp">
            <Header
                tabIndex="1"
                role="listbox"
                onClick={() => {
                    toggleMenu(!isListOpen);
                }}>
                <HeaderTitle data-testid="dropdown-box" value={value}>
                    {value}
                    <Arrow isOpen={isListOpen} />
                </HeaderTitle>
            </Header>
            {isListOpen && (
                <ListWrapper data-testid="dropdown-list">
                    {optionList.map((option) => (
                        <ListItem
                            role="option"
                            key={option.key}
                            onClick={() => {
                                onItemSelect(option);
                                toggleMenu(!isListOpen);
                            }}>
                            {' '}
                            <p> {option.value} </p>
                        </ListItem>
                    ))}
                </ListWrapper>
            )}
        </Wrapper>
    );
};

Dropdown.propTypes = {
    value: string,
    onItemSelect: func.isRequired,
    optionList: array.isRequired
};

Dropdown.defaultProps = {
    value: ''
};

export default Dropdown;
