import React from 'react';
import { shape, arrayOf, array, bool, string } from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from 'TMConstants';
import faqProps from 'TMPropTypes/faqProps';

import FlexWrapper from 'TMComponents/FlexWrapper';
import HeadTags from 'TMComponents/HeadTags';
import Container from 'TMComponents/Container';
import ContentParser from 'TMComponents/ContentParser';
import Question from './Question';
import Accordion from './Accordion';

const ContainerWrapper = styled(Container)`
    @media (max-width: ${breakpoints.mobileTablet}px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const StyledContainer = styled(Container)`
    padding-top: 56px;
    padding-bottom: 56px;
`;

const Header = styled(ContentParser)`
    margin-bottom: 32px;
`;

const Questions = styled(FlexWrapper)`
    div {
        margin-bottom: 48px;
    }
    div:last-child {
        margin-bottom: 0;
    }
`;

const AccordionWrap = styled(FlexWrapper)``;

export const getSchema = (questions) => {
    const questionSchema = questions.map(({ title, summary }) => ({
        '@type': 'Question',
        name: title,
        acceptedAnswer: {
            '@type': 'Answer',
            text: `${summary}`
        }
    }));
    return `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":${JSON.stringify(
        questionSchema
    )}}`;
};

const flatStyle = (questions) => (
    <Questions wrap>
        {questions.map(({ id, ...rest }) => (
            <Question key={id} {...rest} />
        ))}
    </Questions>
);

const accordionStyle = (questions) => (
    <AccordionWrap wrap>
        {questions.map(({ id, ...rest }) => (
            <Accordion key={id} {...rest} />
        ))}
    </AccordionWrap>
);

const FAQ = ({ header, accordion, questions, backgroundVariant }) => (
    <ContainerWrapper>
        <StyledContainer size="small">
            <HeadTags>
                <script type="application/ld+json">
                    {getSchema(questions)}
                </script>
            </HeadTags>

            <Header copy={header} backgroundVariant={backgroundVariant} />
            {accordion ? accordionStyle(questions) : flatStyle(questions)}
        </StyledContainer>
    </ContainerWrapper>
);

FAQ.propTypes = {
    accordion: bool,
    // eslint-disable-next-line react/forbid-prop-types
    header: array,
    backgroundVariant: string,
    questions: arrayOf(shape(faqProps)).isRequired
};

FAQ.defaultProps = {
    accordion: false,
    backgroundVariant: '#fff'
};

export default FAQ;
