import React from 'react';
import NavLink from 'TMComponents/NavLink';
import Container from 'TMComponents/Container';
import Img from 'TMComponents/ImageDynamic';
import { string, number, shape, bool } from 'prop-types';
import imageProps from 'TMPropTypes/imageProps';
import styled from 'styled-components';

const StyledImage = styled(Img)`
    ${({ roundCorners }) => roundCorners && 'border-radius: 24px;'}
`;

const Image = ({
    link = '',
    maxWidth = null,
    image,
    leftAlign = false,
    roundCorners = false
}) => {
    return (
        <Container
            maxWidth={maxWidth}
            leftAlign={leftAlign}
            roundCorners={roundCorners}>
            {link ? (
                <NavLink href={link}>
                    <StyledImage
                        imageData={image}
                        roundCorners={roundCorners}
                    />
                </NavLink>
            ) : (
                <StyledImage
                    imageData={image}
                    roundCorners={roundCorners}
                />
            )}
        </Container>
    );
};

Image.propTypes = {
    image: shape(imageProps).isRequired,
    link: string,
    maxWidth: number,
    leftAlign: bool,
    roundCorners: bool
};

export default Image;
