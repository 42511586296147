/* eslint-disable import/no-unresolved */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { array } from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Content from 'TMComponents/Content';
import ContentParserStructured from 'TMComponents/ContentParserStructured';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Container from 'TMComponents/Container';
import useEmblaCarousel from 'embla-carousel-react';
import AutoHeight from 'embla-carousel-auto-height';
import AccountSelector from './Selector';

const Embla = styled.section`
    overflow: hidden;
    margin: auto;
    --slide-height: auto;
    --slide-spacing: 1rem;
    --slide-size: 95%;
`;

const EmblaViewport = styled.div`
    overflow: hidden;
`;

const EmblaContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    align-items: flex-start;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
`;

const EmblaSlide = styled.div`
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
`;

const EmblaSlideNumber = styled.div`
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
`;

const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
    margin-top: 25px;
    border-radius: 25px / 25px;
    border: 2px solid ${({ theme }) => theme.colorSystem.UIElements[4]};
`;

const Ribbon = styled(FlexWrapper)`
    padding: 20px 18px;
    justify-content: space-between;
    align-items: baseline;
    align-content: space-between;
    ${(props) =>
        props.gradOne &&
        `background-image: linear-gradient(to right, ${props.gradOne}, ${props.gradTwo}); 
    `};

    h3 {
        color: ${({ theme }) => theme.colorSystem.primary[3]};
        margin: 0;
    }
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 12px;
    border-radius: 25px;
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
`;

const ContentStyle = {
    paddingTop: '16px',
    paddingBottom: '16px',
    backgroundColor: '#ffffff'
};

const ContainerPadding = {
    padding: '24px 0'
};

const AccountComparison = ({ heading, accounts }) => {
    const theme = useTheme();
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true
        },
        [AutoHeight()]
    );

    return (
        <Container style={ContainerPadding} size="small">
            <ContentParserStructured
                centerAlignText
                structuredCopy={heading}
            />

            <Embla>
                <AccountSelector accounts={accounts} embla={emblaApi} />
                <EmblaViewport ref={emblaRef} data-testid="embla-viewport">
                    <EmblaContainer>
                        {accounts.map(
                            ({
                                id,
                                accountName,
                                label,
                                cardContent,
                                gradientOne,
                                gradientTwo
                            }) => {
                                return (
                                    <EmblaSlide key={id}>
                                        <EmblaSlideNumber>
                                            <Wrapper>
                                                <Ribbon
                                                    gradOne={gradientOne}
                                                    gradTwo={gradientTwo}>
                                                    <h3>{accountName}</h3>
                                                    <Label label={label}>
                                                        {label}
                                                    </Label>
                                                </Ribbon>
                                                <Content
                                                    backgroundVariant={
                                                        theme.colorSystem
                                                            .primary[3]
                                                    }
                                                    style={ContentStyle}
                                                    content={cardContent}
                                                />
                                            </Wrapper>
                                        </EmblaSlideNumber>
                                    </EmblaSlide>
                                );
                            }
                        )}
                    </EmblaContainer>
                </EmblaViewport>
            </Embla>
        </Container>
    );
};

AccountComparison.propTypes = {
    heading: array.isRequired,
    accounts: array.isRequired
};

export default AccountComparison;
