import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Breadcrumbs } from 'TMComponents/Breadcrumbs';
import Img from 'TMComponents/ImageStatic';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Cross from 'TMComponents/Icons/Cross';
import NavLink from 'TMComponents/NavLink';
import { Body, Nav } from 'TMComponents/Typography';
import ChevronRight from 'TMComponents/Icons/ChevronRight';
import Hamburger from 'TMComponents/Icons/Hamburger';
import ComponentMapper from 'TMComponents/ComponentMapper';
import { Banner } from 'TMComponents/Banner';
import headerProps from 'TMPropTypes/headerProps';
import { headerHeight } from 'TMConstants';

import MenuItem, { MenuItemContainer, MenuItemText } from './MenuItem';

const Wrapper = styled.div`
    position: ${(props) => (props.hideNav ? 'absolute' : 'fixed')};
    width: 100%;
    z-index: 15;
`;

const TopBar = styled.div`
    top: 0;
`;
const NavBar = styled(FlexWrapper)`
    align-items: center;
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    height: 64px;
    padding: 20px;
`;
const Logo = styled(NavLink)``;
const IconWrapper = styled.button`
    background: transparent;
    border: none;
    height: 64px;
    &:focus {
        outline: none;
    }
`;
const Menu = styled.nav`
    ${Nav}
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    color: ${(props) => props.theme.colorSystem.primary[1]};
    display: flex;
    flex-direction: column;
    height: calc(100% - ${headerHeight.mobile}px);
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: -100%;
    transition: top 0.3s;
    width: 100%;
    z-index: -1;
    ${(props) =>
        props.isOpen &&
        `
        top: ${headerHeight.mobile}px;
    `};
`;
const MenuList = styled.ul`
    overflow-y: auto;
    padding-left: 0;
    margin-top: 44px;
`;

const ChevronLeft = styled(ChevronRight)`
    transform: rotate(180deg);
`;
const SubmenuTitle = styled(MenuItemContainer)`
    align-items: center;
    list-style-type: none;
    p {
        color: ${({ theme }) => theme.colorSystem.primary[4]};
        padding-left: 20px;
    }
`;
const SubmenuInner = styled.ul`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    height: 100%;
    left: ${({ isOn }) => (isOn ? '0' : '100%')};
    list-style: none;
    overflow-x: scroll;
    padding: 0;
    position: absolute;
    top: 0;
    transition: left 0.3s;
    width: 100vw;
    z-index: 20;
`;
const SubmenuItemText = styled(Body)`
    margin: 0;
    text-transform: initial;
`;

const BottomSection = styled(FlexWrapper)`
    width: 100%;
    text-align: center;
    margin-top: 20px;
    gap: ${headerHeight.mobile}px;
`;

const MobileHeader = ({
    logo,
    mobileBottomSection = [],
    menuItems,
    isTop,
    hideNav,
    banner
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const { colorSystem } = useContext(ThemeContext);

    useEffect(() => {
        document.body.style.overflowY = menuOpen ? 'hidden' : 'auto';
    }, [menuOpen]);

    const selectedMenuItem = menuItems.find(({ id }) => id === selectedId);

    return (
        <Wrapper hideNav={hideNav}>
            <TopBar>
                {banner?.showBanner && !menuOpen && <Banner {...banner} />}
                <NavBar
                    justify="space-between"
                    align="center"
                    isTop={isTop}
                    isOpen={menuOpen}>
                    <Logo href="/">
                        <Img
                            imageData={logo}
                            width="244"
                            loading="eager"
                        />
                    </Logo>
                    <FlexWrapper justify="space-between" align="center">
                        {!hideNav && (
                            <IconWrapper
                                type="button"
                                onClick={() => setMenuOpen(!menuOpen)}
                                aria-label="Toggle nav menu">
                                {menuOpen ? (
                                    <Cross data-testid="close-menu" />
                                ) : (
                                    <Hamburger
                                        data-testid="open-menu"
                                        color={colorSystem.primary[1]}
                                    />
                                )}
                            </IconWrapper>
                        )}
                    </FlexWrapper>
                </NavBar>
            </TopBar>
            <Breadcrumbs />
            <Menu data-testid="mobile-menu" isOpen={menuOpen}>
                <MenuList>
                    {menuItems.map(({ id, title, url }) => (
                        <MenuItem
                            key={id}
                            title={title}
                            url={url}
                            onClick={() => {
                                if (url) {
                                    setMenuOpen(false);
                                } else {
                                    setSelectedId(id);
                                }
                            }}
                        />
                    ))}
                    <SubmenuInner isOn={!!selectedMenuItem}>
                        {selectedMenuItem && (
                            <div key={`submenu-item-${selectedId}`}>
                                <SubmenuTitle
                                    id={`submenu-title-${selectedId}`}
                                    data-testid="submenu-title"
                                    as="li"
                                    onClick={() => setSelectedId(null)}>
                                    <ChevronLeft />
                                    <MenuItemText variant>
                                        {selectedMenuItem.title}
                                    </MenuItemText>
                                </SubmenuTitle>
                                {selectedMenuItem.submenu.map(
                                    ({ id, link, text }) => (
                                        <div key={`submenu-item-${id}`}>
                                            <NavLink key={id} href={link}>
                                                <MenuItemContainer
                                                    justify="space-between"
                                                    align="center"
                                                    onClick={() => {
                                                        setSelectedId(
                                                            null
                                                        );
                                                        setMenuOpen(false);
                                                    }}
                                                    currentRowSelected={
                                                        id === selectedId
                                                    }
                                                    data-testid={`row-${text}`}>
                                                    <SubmenuItemText grey>
                                                        {text}
                                                    </SubmenuItemText>
                                                </MenuItemContainer>
                                            </NavLink>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </SubmenuInner>
                    {!selectedMenuItem && (
                        <BottomSection direction="column">
                            {mobileBottomSection.map((item) => (
                                <ComponentMapper
                                    key={item.id}
                                    {...item}
                                    onClick={() => setMenuOpen(false)}
                                />
                            ))}
                        </BottomSection>
                    )}
                </MenuList>
            </Menu>
        </Wrapper>
    );
};

MobileHeader.propTypes = headerProps;

export default MobileHeader;
