import React from 'react';
import styled from 'styled-components';
import { number, string } from 'prop-types';
import { breakpoints } from 'TMConstants';

const Wrapper = styled.div`
    margin: ${({ mobileSpacing }) => mobileSpacing}px 0 !important;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: ${({ spacing }) => spacing}px 0 !important;
    }
`;
const Separator = ({ mobileSpacing, spacing, anchor }) => (
    <Wrapper id={anchor} mobileSpacing={mobileSpacing} spacing={spacing} />
);

Separator.propTypes = {
    spacing: number,
    mobileSpacing: number,
    anchor: string
};

Separator.defaultProps = {
    spacing: 0,
    mobileSpacing: 0,
    anchor: ''
};

export default Separator;
