import React from 'react';
import styled from 'styled-components';
import { bool, node } from 'prop-types';
import Checkmark from '../../Icons/Checkmark';

const ListItemStyled = styled.li`
    ${({ listCheckMarks }) =>
        listCheckMarks &&
        `
        display:flex;
        gap:8px
    `}
`;
const ListItem = ({ listCheckMarks, children }) => {
    return (
        <ListItemStyled listCheckMarks={listCheckMarks}>
            {listCheckMarks && (
                // prevents svg shrinking when line content is long
                <div>
                    <Checkmark />
                </div>
            )}
            {children}
        </ListItemStyled>
    );
};

ListItem.propTypes = {
    children: node.isRequired,
    listCheckMarks: bool
};
ListItem.defaultProps = {
    listCheckMarks: false
};

export default ListItem;
